import React, { useState, useContext } from 'react'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { authentication, getEmailFromJwt, getNameFromJwt, VERSION } from '../../lib'
import { ConfirmDeleteAccountDialog } from './ConfirmDeleteAccountDialog'
import { StoreAccessContext } from '../../contexts'
import { AMPLITUDE_HIDE_CLASS_NAME } from '../../lib/amplitudeUtils'

export const GeneralInfo = () => {
  const { intl } = useTranslation()
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
  const { userAccessRequests } = useContext(StoreAccessContext)
  const location = userAccessRequests.find(request => request.status === 'APPROVED')

  return (
    <React.Fragment>
      {showConfirmDeleteDialog && <ConfirmDeleteAccountDialog onClose={() => setShowConfirmDeleteDialog(false)} />}
      <div className="space-y-lg">
        <div className="flex flex-col">
          <small>
            {intl.formatMessage({
              id: 'Name',
              defaultMessage: 'Name',
              description: ''
            })}
          </small>
          <span className={AMPLITUDE_HIDE_CLASS_NAME}>{getNameFromJwt()}</span>
        </div>

        <div className="flex flex-col">
          <small>
            {intl.formatMessage({
              id: 'Email',
              defaultMessage: 'Email',
              description: ''
            })}
          </small>
          <span className={AMPLITUDE_HIDE_CLASS_NAME}>{getEmailFromJwt()}</span>
        </div>

        <div className="flex flex-col">
          <small>
            {intl.formatMessage({
              id: 'Store_Id',
              defaultMessage: 'Store ID',
              description: ''
            })}
          </small>
          <span>{location?.alias}</span>
        </div>

        <div className="flex flex-col">
          <small>
            {intl.formatMessage({
              id: 'Location_Id',
              defaultMessage: 'Location ID',
              description: ''
            })}
          </small>
          <span>{location?.locationId}</span>
        </div>

        <div className="flex flex-col">
          <small>
            {intl.formatMessage({
              id: 'App_Version',
              defaultMessage: 'Version',
              description: ''
            })}
          </small>
          <span>{VERSION}</span>
        </div>

        <button className="btn btn-primary-dark w-full" onClick={authentication.goToAccountManagement}>
          {intl.formatMessage({
            id: 'Manage_Account',
            defaultMessage: 'Manage account',
            description: ''
          })}
        </button>

        <button className="btn btn-primary-danger w-full" onClick={() => setShowConfirmDeleteDialog(true)}>
          {intl.formatMessage({
            id: 'Delete_User',
            defaultMessage: 'Delete user',
            description: ''
          })}
        </button>
      </div>
    </React.Fragment>
  )
}
