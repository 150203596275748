import React, { ReactNode } from 'react'
import { defaultToggles } from '../components/settings/notifications/defaultToggles'
import { logError } from '@tomra/datadog-browser-logging'
import { type Unsubscribe } from 'firebase/firestore'
import { subscribeToUserDoc, updateUserDoc } from '../services'
import { ampli } from '../ampli'

export const NotificationsContext = React.createContext<{
  toggles: NotificationToggleType[]
  setupNotificationsTogglesListener: (locationid: string) => void
  updateToggles: (toggles: NotificationToggleType[], locationId: string) => void
}>({
  toggles: defaultToggles,
  setupNotificationsTogglesListener: () => {
    throw new Error('Function not provided')
  },
  updateToggles: () => {
    throw new Error('Function not provided')
  }
})

type Props = {
  children: ReactNode
}

type State = {
  toggles: NotificationToggleType[]
}

export class NotificationsProvider extends React.Component<Props, State> {
  _unsubscribe: Unsubscribe = () => {}

  state: State = {
    toggles: defaultToggles
  }

  _setupNotificationsTogglesListener = async (locationId: string) => {
    this._unsubscribe = subscribeToUserDoc(
      locationId,
      data => {
        if (data.toggles) {
          // Use remote toggle data if exists, otherwise use default toggle data
          const updatedToggles = defaultToggles.reduce((acc: NotificationToggleType[], toggle) => {
            const firestoreToggle = data?.toggles?.find(firestoreToggle => firestoreToggle.id === toggle.id)
            acc.push(firestoreToggle || toggle)
            return acc
          }, [])

          const active_notifications = updatedToggles.filter(toggle => toggle.enabled).map(toggle => toggle.id)
          ampli.identify(undefined, { active_notifications })

          this.setState({ toggles: updatedToggles })

          // Make sure Firestore is populated with default toggles not already in Firestore
          this._updateToggles(updatedToggles, locationId)
        }
      },
      (error?: any) => {
        error && logError(new Error('Failed to fetch notification toggles from Firestore'), error)
      }
    )
  }

  _updateToggles = async (toggles: NotificationToggleType[], locationId: string) => {
    updateUserDoc(locationId, { toggles }).catch(error => {
      error && logError(new Error('Failed to update notification toggle in Firestore'), error)
    })
  }

  componentWillUnmount(): void {
    this._unsubscribe()
  }

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          toggles: this.state.toggles,
          setupNotificationsTogglesListener: this._setupNotificationsTogglesListener,
          updateToggles: this._updateToggles
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    )
  }
}
