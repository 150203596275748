import React from 'react'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { usesiOSNativeWrapper } from '../../../services'

export const NotificationPermissionHelp = () => {
  const { intl } = useTranslation()

  if (usesiOSNativeWrapper()) {
    return (
      <>
        <span>
          {intl.formatMessage({
            id: 'NotificationPermissionHelp.IOS',
            defaultMessage: 'Please enable notification permissions on your device by opening ',
            description: ''
          })}
        </span>
        <b>
          {intl.formatMessage({
            id: 'NotificationPermissionHelp.Instructions.IOS',
            defaultMessage: 'Settings -> Notifications -> Notify+Assist -> Allow notifications',
            description: ''
          })}
        </b>
      </>
    )
  } else if (window.chrome) {
    return (
      <>
        <span>
          {intl.formatMessage({
            id: 'NotificationPermissionHelp.Android',
            defaultMessage: 'Please enable notification permission on your device by opening Chrome and navigating to ',
            description: ''
          })}
        </span>
        <b>
          {intl.formatMessage(
            {
              id: 'NotificationPermissionHelp.Instructions.Android',
              defaultMessage:
                'Settings -> Site settings -> All sites -> {appDomain} -> Toggle on Notification permission',
              description: ''
            },
            { appDomain: window.location.origin }
          )}
        </b>
      </>
    )
  } else {
    return (
      <>
        <span>
          {intl.formatMessage({
            id: 'NotificationPermissionHelp.Generic',
            defaultMessage: 'Please check camera permissions on your device by opening your browser and navigating to ',
            description: ''
          })}
        </span>
        <b>
          {intl.formatMessage(
            {
              id: 'NotificationPermissionHelp.Instructions.Generic',
              defaultMessage: 'Settings -> Site settings -> enable Camera for {appDomain}',
              description: ''
            },
            { appDomain: window.location.origin }
          )}
        </b>
      </>
    )
  }
}
