import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'

const envToCloudFunctionRegionMap = {
  eu: 'europe-west1',
  us: 'us-central1',
  au: 'australia-southeast1'
}

// @ts-ignore
const isE2E = window.Cypress

const { ENV, STAGE, VERSION, WEB_PUSH_PUBLIC_KEY, DATADOG_CONFIG, CONFIG_BY_STAGE } = window.__ENVIRONMENT__
const { FIREBASE_CONFIG, AMPLITUDE_CONFIG, CONFIG_BY_ENV } = CONFIG_BY_STAGE[STAGE]
const { KEYCLOAK_HOST, API_HOST } = CONFIG_BY_ENV[ENV]

const app = initializeApp(FIREBASE_CONFIG)
const db = isE2E ? getFirestore(app) : getFirestore(app, `${ENV}-${STAGE}`)
const auth = getAuth(app)
const functions = getFunctions(app, envToCloudFunctionRegionMap[ENV])

if (isE2E) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
}

const FIREBASE = {
  app,
  db,
  auth,
  functions
}

export { ENV, STAGE, VERSION, WEB_PUSH_PUBLIC_KEY, KEYCLOAK_HOST, API_HOST, FIREBASE, DATADOG_CONFIG, AMPLITUDE_CONFIG }
