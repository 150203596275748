import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { PieChart as DefaultPieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

const CenterContainer = styled.div<{ primaryColor: string }>`
  > *:first-child {
    font-size: var(--font-size-xl);
    color: ${props => props.primaryColor};
  }
`

interface Props {
  primaryColor: string
  secondaryColor: string
  percentage: number
  size?: number
  children: ReactNode
}

export const PieChart = ({ primaryColor, secondaryColor, percentage, size = 220, children }: Props) => {
  const data = [{ value: 100 - percentage }, { value: percentage }]

  return (
    <div className="relative">
      <ResponsiveContainer minWidth={size} height={size}>
        <DefaultPieChart>
          <Pie data={data} dataKey="value" innerRadius={60} startAngle={90} endAngle={450} isAnimationActive={false}>
            <Cell key="secondaryCell" fill={secondaryColor} />
            <Cell key="primaryCell" fill={primaryColor} />
          </Pie>
        </DefaultPieChart>
      </ResponsiveContainer>

      <CenterContainer className="centerAbsolute text-center text-grey" primaryColor={primaryColor}>
        {children}
      </CenterContainer>
    </div>
  )
}

export default PieChart
