import React, { useState, useEffect } from 'react'
import { initDatadog, datadogLogs, datadogRum } from '@tomra/datadog-browser-logging'
import { ErrorBoundary } from 'react-error-boundary'
import { authentication, getUserIdFromJwt, DATADOG_CONFIG, VERSION, ENV, STAGE } from './lib'
import { usesiOSNativeWrapper, isOnPwa, getNativeAppVersion } from './services'
import { WelcomePage } from './components/login/WelcomePage'
import '@tomra/design-system/src/config/tailwind.config.css'
import './styles/content.css'
import { createRoot } from 'react-dom/client'
import { Router } from './Router'
import { ReactI18nProvider } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import translationLoader from './translations/translationLoader'
import { ContextProvider } from './contexts'
import { initAmplitude } from './lib/amplitudeUtils'
import { ampli } from './ampli'
import { GeneralErrorBoundary, onGeneralErrorBoundary } from './components/ErrorPage'

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

const Index = () => {
  const [authenticated, setAuthenticated] = useState<'tbd' | boolean>('tbd')

  useEffect(() => {
    const initAuth = async () => {
      try {
        initAmplitude()

        await authentication.init()

        ampli.userAuthenticated()
        datadogRum.setUser({ id: getUserIdFromJwt() })
        datadogLogs.setUser({ id: getUserIdFromJwt() })

        setAuthenticated(true)
      } catch (error: any) {
        setAuthenticated(false)
      }
    }

    initAuth()

    initDatadog({
      ...DATADOG_CONFIG,
      version: VERSION,
      env: `${ENV}-${STAGE}`,
      contextProperties: {
        usesiOSNativeWrapper: usesiOSNativeWrapper(),
        isOnPwa: isOnPwa(),
        nativeAppVersion: getNativeAppVersion()
      }
    })
  }, [])

  if (authenticated === 'tbd') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  return (
    <ErrorBoundary fallbackRender={GeneralErrorBoundary} onError={onGeneralErrorBoundary}>
      <ReactI18nProvider translationsLoader={translationLoader}>
        <ContextProvider>{authenticated ? <Router /> : <WelcomePage />}</ContextProvider>
      </ReactI18nProvider>
    </ErrorBoundary>
  )
}

root.render(<Index />)
