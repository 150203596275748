import React, { useEffect, useContext, useState } from 'react'
import { initAPNsPush, usesiOSNativeWrapper, initWebPush, initFirebaseAuth } from '../../services'
import { ConnectionDownIcon } from '../icons'
import { InstallationsContext, StoreAccessContext, NotificationsContext } from '../../contexts'
import { isMachineDown, isMachineOffline } from '../../lib'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { ampli } from '../../ampli'

export const StatusBar = () => {
  const { intl } = useTranslation()
  const { installations, setupInstallationsListener } = useContext(InstallationsContext)
  const { setupNotificationsTogglesListener } = useContext(NotificationsContext)
  const { userAccessRequests } = useContext(StoreAccessContext)
  const approvedLocation = userAccessRequests.find(request => request.status === 'APPROVED')
  const [isOnline, setIsOnline] = useState(true)

  const numberDown = installations.filter(isMachineDown).length
  const numberOffline = installations.filter(isMachineOffline).length

  useEffect(() => {
    let unsubscribeFirebaseAuth = () => {}

    if (approvedLocation) {
      approvedLocation.alias && ampli.client?.setGroup('store', approvedLocation.alias)
      ampli.client?.setGroup('location', approvedLocation.locationId)

      initFirebaseAuth(approvedLocation.locationId, () => {
        usesiOSNativeWrapper() ? initAPNsPush(approvedLocation.locationId) : initWebPush(approvedLocation.locationId)
        setupInstallationsListener(approvedLocation)
        setupNotificationsTogglesListener(approvedLocation.locationId)
      }).then(unsubscribe => {
        unsubscribeFirebaseAuth = unsubscribe
      })
    }

    const _handleOnline = () => setIsOnline(true)
    const _handleOffline = () => setIsOnline(false)

    window.addEventListener('online', _handleOnline)
    window.addEventListener('offline', _handleOffline)

    return () => {
      unsubscribeFirebaseAuth()

      window.removeEventListener('online', _handleOnline)
      window.removeEventListener('offline', _handleOffline)
    }
  }, [])

  // Connection check first prio
  if (!isOnline) {
    return (
      <div className="bg-yellow flex space-x-sm items-center justify-center p-sm font-bold text-md">
        <ConnectionDownIcon color="black" width="1.5rem" height="1.5rem" />
        <span>
          {intl.formatMessage({
            id: 'No_Internet_Connection',
            defaultMessage: 'No internet connection',
            description: ''
          })}
        </span>
      </div>
    )
  }
  // second prio: display nothing if no installations
  if (installations.length === 0) return null

  // numberDown third prio
  if (numberDown > 0) {
    return (
      <div className="bg-red text-white flex items-center justify-center p-sm font-bold text-md">
        {intl.formatMessage(
          {
            id: 'Machines_Down',
            defaultMessage:
              '{numberDown} {numberDown, plural, one {machine has stopped!} other {machines have stopped!}}',
            description: ''
          },
          { numberDown }
        )}
      </div>
    )
  }

  // numberOffline fourth prio
  if (numberOffline > 0) {
    return (
      <div className="bg-yellow text-black flex items-center justify-center p-sm font-bold text-md">
        {intl.formatMessage(
          {
            id: 'Machines_Offline',
            defaultMessage:
              '{numberOffline} {numberOffline, plural, one {machine is offline!} other {machines are offline!}}',
            description: ''
          },
          { numberOffline }
        )}
      </div>
    )
  }

  // All good
  return (
    <div className="bg-green text-black flex items-center justify-center p-sm font-bold text-md">
      {intl.formatMessage({
        id: 'Machines_Healthy',
        defaultMessage: 'All machines are operational',
        description: ''
      })}
    </div>
  )
}
