import React, { useState, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { InstallationsContext, StoreAccessContext } from '../../contexts'
import { Rvm, RvmSkeleton } from './Rvm'
import { RvmSmall, RvmSmallSkeleton } from './RvmSmall'
import { DropArea } from './DropArea'

export const RvmList = () => {
  const [draggedIndex, setDraggedIndex] = useState<number>(0)
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const { installations } = useContext(InstallationsContext)
  const { userAccessRequests } = useContext(StoreAccessContext)
  const approvedLocation = userAccessRequests.find(request => request.status === 'APPROVED')
  const approvedLocationInstallationIds = approvedLocation?.installations || []

  return (
    <>
      {isDesktop ? (
        <div className="flex justify-center items-start flex-wrap mt-xl">
          {installations
            .sort((a, b) => (a.position || 0) - (b.position || 0))
            .map((installation, index) => (
              <React.Fragment key={installation.installationId}>
                {index === 0 && <DropArea draggedIndex={draggedIndex} index={index} />}
                <Rvm installation={installation} setDraggedIndex={setDraggedIndex} index={index} />
                <DropArea draggedIndex={draggedIndex} index={index + 1} />
              </React.Fragment>
            ))}

          {approvedLocationInstallationIds.slice(installations.length).map(installationId => (
            <RvmSkeleton key={installationId} />
          ))}
        </div>
      ) : (
        <>
          {installations
            .sort((a, b) => (a.position || 0) - (b.position || 0))
            .map(installation => (
              <RvmSmall key={installation.installationId} installation={installation} />
            ))}

          {approvedLocationInstallationIds.slice(installations.length).map(installationId => (
            <RvmSmallSkeleton key={installationId} />
          ))}
        </>
      )}
    </>
  )
}
