import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { AppMessageContext } from '../contexts'
import { Drawer } from './common'
import { isOnPwa } from '../services'
import { setItemInWebStorage, getItemFromWebStorage } from '../lib'

export const InstallAppDrawer = () => {
  const { intl } = useTranslation()
  const { pushMessage } = useContext(AppMessageContext)
  const [visible, setVisible] = useState(false)
  const [installEvent, setInstallEvent] = useState<any | null>(null)

  const closeDrawerForGood = () => {
    setItemInWebStorage('HAS_SHOWN_PWA_PROMPT', true)
    setVisible(false)
  }

  const promptInstall = async () => {
    if (installEvent && installEvent.prompt) {
      try {
        const result = await installEvent.prompt()

        if (result?.accepted) {
          pushMessage({
            formattedMessage: (
              <>
                {intl.formatMessage({
                  id: 'Success_Installing',
                  defaultMessage: 'Great! Installing...',
                  description: 'After agreeing to install app'
                })}
              </>
            ),
            type: 'success',
            duration: 5000
          })
        }

        closeDrawerForGood()
      } catch (error) {
        closeDrawerForGood()
      }
    }
  }

  useEffect(() => {
    // This will trigger upon desktop -> standalone, i.e after a desktop install
    window.matchMedia('(display-mode: standalone)').addListener(event => {
      if (event.matches) {
        closeDrawerForGood()
      }
    })

    const handleInstallPrompt = async event => {
      event.preventDefault()

      const hasShownPrompt = await getItemFromWebStorage('HAS_SHOWN_PWA_PROMPT')

      if (!isOnPwa() && !hasShownPrompt) {
        setVisible(true)
      }

      setInstallEvent(event)
    }

    window.addEventListener('beforeinstallprompt', handleInstallPrompt)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt)
    }
  }, [])

  return (
    <Drawer onClose={closeDrawerForGood} visible={visible}>
      <div className="flex flex-col space-y-lg">
        <h1 className="text-xl">
          {intl.formatMessage({
            id: 'Install_App_Question',
            defaultMessage: 'Install app?',
            description: ''
          })}
        </h1>

        <p>
          {intl.formatMessage({
            id: 'App_Can_Be_Installed',
            defaultMessage:
              'Notify+Assist can be installed on your device as an app. It will only take a couple of seconds.',
            description: ''
          })}
        </p>

        <button className="btn btn-primary-dark w-full" onClick={promptInstall}>
          {intl.formatMessage({
            id: 'Yes',
            defaultMessage: 'Yes',
            description: 'Simple yes text/choice'
          })}
        </button>

        <button className="btn" onClick={closeDrawerForGood}>
          {intl.formatMessage({
            id: 'No',
            defaultMessage: 'No',
            description: 'Simple no text/choice'
          })}
        </button>
      </div>
    </Drawer>
  )
}
