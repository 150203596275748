import React, { useRef, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { RvmName } from './RvmName'
import { RvmBadge } from './RvmBadge'
import { RvmInfo } from './RvmInfo'
import { rvmColorMap } from './rvmColorMap'

interface Props {
  installation: InstallationType
  setDraggedIndex: (index: number) => void
  index: number
}

export const RvmSkeleton = () => {
  const { intl } = useTranslation()

  return (
    <div
      aria-label="rvm skeleton"
      className="animate-zoomIn-fast card w-15 flex flex-col items-center p-lg mb-xl mr-xl"
    >
      <div className="relative flex flex-col items-center h-13">
        <div className="animate-pulse h-13 w-8 bg-[rgb(220,220,220)] rounded-half" />
        <div className="animate-pulse absolute -left-lg bottom-xl w-4 h-4 bg-[rgb(220,220,220)] rounded" />
      </div>

      <div className="animate-pulse my-md w-8 h-1 bg-[rgb(220,220,220)] rounded-half" />

      <small className="text-center ">
        {intl.formatMessage({
          id: 'Awaiting_Event',
          defaultMessage: 'Awaiting event from machine...',
          description: ''
        })}
      </small>
    </div>
  )
}

export const Rvm = ({ installation, setDraggedIndex, index }: Props) => {
  const [isBeingDragged, setIsBeingDragged] = useState(false)
  const _dragImage = useRef<HTMLImageElement>(null)
  const [machineType, setMachineType] = useState<string>(installation.machineType?.toUpperCase() || 'UNKNOWN')
  const detail = installation.currentStatus?.detail
  const rvmColor = rvmColorMap[detail] || ''

  const _onImageLoadError = () => {
    logError(new Error(`Failed to load RVM image for machineType: ${machineType}`))
    setMachineType('UNKNOWN')
  }

  const _onDragStart = (evt: React.DragEvent) => {
    if (_dragImage.current) {
      evt.dataTransfer.setDragImage(_dragImage.current, 0, 0)
      setDraggedIndex(index)
      setIsBeingDragged(true)
    }
  }

  const _onDragEnd = () => {
    setIsBeingDragged(false)
  }

  return (
    <div
      className={`animate-zoomIn-fast card min-w-15 max-w-22 flex flex-col items-center mb-xl cursor-move ${
        isBeingDragged ? 'opacity-20' : 'opacity-1'
      }`}
      onDragStart={_onDragStart}
      onDragEnd={_onDragEnd}
      draggable
      data-testid={`draggable-${name}`}
    >
      <div className={`relative flex flex-col items-center m-xl mb-0 h-13 ${rvmColor}`}>
        <img
          className="h-13"
          alt={`RVM model ${machineType}`}
          src={`/images/rvms/${machineType}.svg`}
          onError={_onImageLoadError}
          ref={_dragImage}
        />

        <RvmBadge statusDetail={detail} />
      </div>

      <RvmName installation={installation} />

      <RvmInfo installation={installation} />
    </div>
  )
}
