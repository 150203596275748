import { RVMFlag, UIFlag } from './rvmFlags'

export function isMachineDown({ currentStatus }: InstallationType): boolean {
  const detail = currentStatus.detail

  return (
    detail !== UIFlag.MACHINE_OFFLINE.id &&
    detail !== RVMFlag.BIN_STATUS_FULL.id &&
    detail !== RVMFlag.BIN_STATUS_SEMI_FULL.id &&
    detail !== RVMFlag.TABLE_SEMI_FULL.id &&
    detail !== RVMFlag.CRATE_CONVEYOR_SEMI_FULL.id &&
    detail !== UIFlag.MACHINE_HEALTHY.id
  )
}
