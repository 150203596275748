import React from 'react'
import { LanguageIcon } from '../icons'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { ampli } from '../../ampli'

const LanguageSelect = () => {
  const { intl, availableLocales, locale, changeLocale } = useTranslation()

  return (
    <div className="btn btn-icon relative w-[3rem] h-[2rem]">
      <select
        aria-label="Select language"
        className="appearance-none text-atlantic bg-transparent border-0 absolute top-0 left-0 cursor-pointer focus:outline-none w-full h-full"
        value={locale}
        onChange={e => {
          changeLocale(e.target.value)
          ampli.languageSelected({ language_code: e.target.value })
        }}
      >
        {availableLocales.map(langCode => {
          const langTranslation = intl.formatDisplayName(langCode, { type: 'language' })

          return (
            <option key={langCode} value={langCode}>
              {langTranslation || langCode}
            </option>
          )
        })}
      </select>

      <div className="pointer-events-none">
        <LanguageIcon color="white" width="2rem" height="2rem" />
      </div>
    </div>
  )
}

export default LanguageSelect
