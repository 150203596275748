import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AppContainer } from './AppContainer'
import { RouterErrorBoundary } from './components/ErrorPage'
import { SettingsPage, StatusPage, StatisticsPage, UsersPage, GeneralInfo, NotificationsInfo } from './components'

export const Router = () => (
  <RouterProvider
    router={createBrowserRouter([
      {
        element: <AppContainer />,
        errorElement: <RouterErrorBoundary />,
        children: [
          {
            path: '*',
            element: <StatusPage />
          },
          {
            path: 'statistics',
            element: <StatisticsPage />
          },
          {
            path: 'users',
            element: <UsersPage />
          },
          {
            path: 'settings',
            element: <SettingsPage />,
            children: [
              {
                path: 'general',
                element: <GeneralInfo />
              },
              {
                path: 'notifications',
                element: <NotificationsInfo />
              }
            ]
          }
        ]
      }
    ])}
    fallbackElement={
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    }
  />
)
