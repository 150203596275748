import React, { useState, useEffect, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { AppMessages } from './components/AppMessages'
import { AppUpdatedDialog } from './components/AppUpdatedDialog'
import { NativeAppUpdatedDrawer } from './components/NativeAppUpdatedDrawer'
import { InstallAppDrawer } from './components/InstallAppDrawer'
import { Header } from './components/header/Header'
import { StatusBar } from './components/header/StatusBar'
import { RequestStoreAccess } from './components'
import { StoreAccessContext } from './contexts'

export const AppContainer = () => {
  const { intl } = useTranslation()
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('loading')
  const { userAccessRequests, fetchUserAccessRequests } = useContext(StoreAccessContext)
  const approvedLocation = userAccessRequests.find(request => request.status === 'APPROVED')

  useEffect(() => {
    fetchUserAccessRequests().then(
      () => {
        setRequestStatus('success')
      },
      () => setRequestStatus('failed')
    )
  }, [])

  return requestStatus === 'loading' ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" />
    </div>
  ) : requestStatus === 'failed' ? (
    <div className="centerAbsolute">
      <div className="flex flex-col items-center">
        <div className="alert alert-danger max-w-30">
          <p>
            {intl.formatMessage({
              id: 'Fetch_Access_Requests_Failed',
              defaultMessage: 'Uh-oh, seems we were unable to check for store access. Please try again later.',
              description: ''
            })}
          </p>
        </div>

        <button onClick={() => (window.location.href = '/')} className="btn btn-primary-dark mt-lg">
          {intl.formatMessage({
            id: 'Reload',
            defaultMessage: 'Reload.',
            description: ''
          })}
        </button>
      </div>
    </div>
  ) : (
    <>
      <AppMessages />
      <AppUpdatedDialog />
      <NativeAppUpdatedDrawer />
      <InstallAppDrawer />

      {approvedLocation ? (
        <>
          <Header />
          <StatusBar />
          <Outlet />
        </>
      ) : (
        <RequestStoreAccess />
      )}
    </>
  )
}
