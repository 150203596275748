import { fetch as _fetch, fetchData as _fetchData } from '@tomra/client-side-http-client'
import { authentication } from './authentication'
import { API_HOST } from './environment'

const config = {
  fetchNewToken: authentication?.fetchNewToken,
  getToken: authentication?.getToken,
  retryCount: 0
}

export const authenticatedFetchData = (path: string, options = {}, apiHost = API_HOST) => {
  return _fetchData(apiHost + path, options, config)
}

export const authenticatedFetchRaw = (path: string, options = {}, apiHost = API_HOST) => {
  return _fetch(apiHost + path, options, config)
}

export const authenticatedFetchDataRetryable = (path: string, options = {}, apiHost = API_HOST) => {
  return _fetchData(apiHost + path, options, { ...config, retryCount: 3 })
}
