import {
  BottlesIcon,
  ErrorIcon,
  DoorIcon,
  PrinterIcon,
  UpdateIcon,
  CheckmarkIcon,
  ConnectionDownIcon,
  CleaningIcon,
  CleaningShortIcon,
  CrateIcon,
  TableIcon
} from '../icons'
import { RVMFlag, RVMFlagDescription, UIFlag } from '../../lib/rvmFlags'

type ColorSetting = { backgroundColor: string; iconColor: string }

type BadgeMap = {
  [key: string]: {
    icon: {
      src: any
      size?: string
    }
    colorSetting: { normal: ColorSetting; inverted: ColorSetting }
    labelText: { id: string; defaultMessage: string; description?: string }
  }
}

export const badgeColorAndIconMap: BadgeMap = {
  FRONT_DOOR_OPEN: {
    icon: {
      src: DoorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.FRONT_DOOR_OPEN
  },
  EMERGENCY: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.EMERGENCY
  },
  STANDBY: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.STANDBY
  },
  EMPTYING_BIN: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.EMPTYING_BIN
  },
  FULL_BIN: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.FULL_BIN
  },
  FULL_TABLE: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.FULL_TABLE
  },
  CLOSED: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.CLOSED
  },
  FULL_CONVEYOR: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.FULL_CONVEYOR
  },
  PRINTER_OUT_OF_PAPER: {
    icon: {
      src: PrinterIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.PRINTER_OUT_OF_PAPER
  },
  PRINTER_STORE_RESOLVABLE: {
    icon: {
      src: PrinterIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.PRINTER_STORE_RESOLVABLE
  },
  PRINTER_OTHER: {
    icon: {
      src: PrinterIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.PRINTER_OTHER
  },
  ERROR_BACKROOM: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.ERROR_BACKROOM
  },
  ERROR_OTHER: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.ERROR_OTHER
  },
  CHAMBER_BLOCKED: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.CHAMBER_BLOCKED
  },
  CLOSED_UPDATE: {
    icon: {
      src: UpdateIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.CLOSED_UPDATE
  },
  TEMPORARY_CLOSED: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.TEMPORARY_CLOSED
  },
  TEMPORARY_CLOSED_REAR_DOOR_OPEN: {
    icon: {
      src: DoorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.TEMPORARY_CLOSED_REAR_DOOR_OPEN
  },
  TEMPORARY_CLOSED_MACHINE_BEING_EMPTIED: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.TEMPORARY_CLOSED_MACHINE_BEING_EMPTIED
  },
  TEMPORARY_CLOSED_RESET_BUTTON: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.TEMPORARY_CLOSED_RESET_BUTTON
  },
  TEMPORARY_CLOSED_NEEDS_EMPTYING: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.TEMPORARY_CLOSED_NEEDS_EMPTYING
  },
  TEMPORARY_CLOSED_BACKROOM_NOT_READY: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.TEMPORARY_CLOSED_BACKROOM_NOT_READY
  },
  BIN_STATUS_SEMI_FULL: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: RVMFlag.BIN_STATUS_SEMI_FULL
  },
  BIN_STATUS_FULL: {
    icon: {
      src: BottlesIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: RVMFlag.BIN_STATUS_FULL
  },
  TABLE_SEMI_FULL: {
    icon: {
      src: TableIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: RVMFlag.TABLE_SEMI_FULL
  },
  CRATE_CONVEYOR_SEMI_FULL: {
    icon: {
      src: CrateIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: RVMFlag.CRATE_CONVEYOR_SEMI_FULL
  },
  MACHINE_OFFLINE: {
    icon: {
      src: ConnectionDownIcon,
      size: '1.5rem'
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: UIFlag.MACHINE_OFFLINE
  },
  CLEANING_WARNING: {
    icon: {
      src: CleaningIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: UIFlag.CLEANING_WARNING
  },
  CLEANING_OVERDUE: {
    icon: {
      src: CleaningIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: UIFlag.CLEANING_OVERDUE
  },
  CLEANING_SHORT: {
    icon: {
      src: CleaningShortIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-yellow)',
        iconColor: 'black'
      },
      inverted: {
        backgroundColor: 'black',
        iconColor: 'var(--colors-yellow)'
      }
    },
    labelText: UIFlag.CLEANING_TOO_SHORT
  },
  UNKNOWN: {
    icon: {
      src: ErrorIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-red)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-red)'
      }
    },
    labelText: RVMFlagDescription.UNKNOWN
  },
  MACHINE_HEALTHY: {
    icon: {
      src: CheckmarkIcon
    },
    colorSetting: {
      normal: {
        backgroundColor: 'var(--colors-green)',
        iconColor: 'white'
      },
      inverted: {
        backgroundColor: 'white',
        iconColor: 'var(--colors-green)'
      }
    },
    labelText: UIFlag.MACHINE_HEALTHY
  }
}
