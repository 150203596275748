import React, { useContext } from 'react'
import { getEmailFromJwt, ENV } from '../../../lib'
import { LockIcon } from '../../icons'
import { StoreAccessContext } from '../../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

function mailtoQueryParameters(locationId: string, email: string) {
  const subject = 'Upgrade to Notify+Assist Premium'
  const body = `\
  Hi,

  I would like to upgrade to Notify+Assist Premium.
  Here's my info:

  - Account: ${email}
  - Store/location: ${locationId}
  - Environment: ${ENV}

  Could you help me?\
  `

  return `subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}

export const UnlockPremium = () => {
  const { intl } = useTranslation()
  const { userAccessRequests } = useContext(StoreAccessContext)
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''

  return (
    <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-white-transparent p-lg rounded-half z-10 text-center space-y-md">
      <LockIcon width="4rem" height="4rem" />

      <b>
        {intl.formatMessage({
          id: 'UnlockPremium_Description',
          defaultMessage: 'Upgrade to Premium to unlock pre-warning notifications.',
          description: ''
        })}
      </b>

      <a href={`mailto:marketsupport.tomraproducts@tomra.com?${mailtoQueryParameters(locationId, getEmailFromJwt())}`}>
        <button className="btn btn-primary-dark">
          {intl.formatMessage({
            id: 'UnlockPremium_Contact_Us',
            defaultMessage: 'Contact us to upgrade',
            description: ''
          })}
        </button>
      </a>
    </div>
  )
}
