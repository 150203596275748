import React from 'react'
import { useNavigate, Outlet, NavLink } from 'react-router-dom'
import { CloseIcon } from '../icons'
import { hasNotificationSupport } from '../../services'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

export const SettingsPage = () => {
  const { intl } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="overflow-scroll absolute top-0 left-0 bg-white w-screen h-screen z-[1]">
      <button className="btn btn-icon absolute top-lg right-lg" onClick={() => navigate('../..')}>
        <CloseIcon width="1.5rem" height="1.5rem" />
      </button>

      <div className="max-w-25 m-auto p-lg space-y-xl">
        <h1 className="text-xl">
          {intl.formatMessage({
            id: 'Settings',
            defaultMessage: 'Settings',
            description: ''
          })}
        </h1>

        <div className="tabs">
          <NavLink to="general" className="tab text-sm">
            {intl.formatMessage({
              id: 'Settings_General',
              defaultMessage: 'General',
              description: ''
            })}
          </NavLink>

          {hasNotificationSupport() && (
            <NavLink to="notifications" className="tab text-sm">
              {intl.formatMessage({
                id: 'Notifications',
                defaultMessage: 'Notifications',
                description: ''
              })}
            </NavLink>
          )}
        </div>

        <Outlet />
      </div>
    </div>
  )
}
