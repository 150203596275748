export const defaultToggles = [
  {
    id: 'FRONT_DOOR_OPEN',
    enabled: false,
    premium: false
  },
  {
    id: 'EMERGENCY',
    enabled: true,
    premium: false
  },
  {
    id: 'STANDBY',
    enabled: false,
    premium: false
  },
  {
    id: 'FULL_TABLE',
    enabled: true,
    premium: false
  },
  {
    id: 'CLOSED',
    enabled: false,
    premium: false
  },
  {
    id: 'CHAMBER_BLOCKED',
    enabled: true,
    premium: false
  },
  {
    id: 'FULL_CONVEYOR',
    enabled: true,
    premium: false
  },
  {
    id: 'PRINTER_OUT_OF_PAPER',
    enabled: true,
    premium: false
  },
  {
    id: 'PRINTER_STORE_RESOLVABLE',
    enabled: true,
    premium: false
  },
  {
    id: 'PRINTER_OTHER',
    enabled: true,
    premium: false
  },
  {
    id: 'ERROR_BACKROOM',
    enabled: true,
    premium: false
  },
  {
    id: 'ERROR_OTHER',
    enabled: true,
    premium: false
  },
  {
    id: 'CLOSED_UPDATE',
    enabled: false,
    premium: false
  },
  {
    id: 'TEMPORARY_CLOSED',
    enabled: true,
    premium: false
  },
  {
    id: 'TEMPORARY_CLOSED_RESET_BUTTON',
    enabled: true,
    premium: false
  },
  {
    id: 'FULL_BIN',
    enabled: false,
    premium: false
  },
  {
    id: 'SINGLE_BIN_FULL',
    enabled: false,
    premium: false
  },
  {
    id: 'ASSISTANCE_NEEDED',
    enabled: true,
    premium: false
  },
  {
    id: 'BIN_STATUS_SEMI_FULL',
    enabled: false,
    premium: true
  },
  {
    id: 'TABLE_SEMI_FULL',
    enabled: false,
    premium: true
  },
  {
    id: 'CRATE_CONVEYOR_SEMI_FULL',
    enabled: false,
    premium: true
  }
]
