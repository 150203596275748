import React, { useState } from 'react'
import { Drawer } from './common'
import appStoreLogo from '../images/appStores/app-store.png'
import playStoreLogo from '../images/appStores/play-store.png'
import { satisfiesNativeAppVersion, openAppUrl, getNativeDeviceInfo } from '../services/react-native-service'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

const NATIVE_APP_VERSIONS = {
  ios: '5.0',
  android: '5.0'
}

export const NativeAppUpdatedDrawer = () => {
  const { intl } = useTranslation()
  const OS = getNativeDeviceInfo()?.OS
  const [showDialog, setShowDialog] = useState(satisfiesNativeAppVersion(NATIVE_APP_VERSIONS) === false)

  const openAppOrPlayStore = () => {
    OS === 'IOS'
      ? openAppUrl('https://apps.apple.com/au/app/id1209927046')
      : openAppUrl('https://play.google.com/store/apps/details?id=com.tomra.digital.dashboard')

    setShowDialog(false)
  }

  return (
    <Drawer onClose={() => setShowDialog(false)} visible={showDialog}>
      <div className="flex flex-colspace-y-lg">
        <h1 className="text-xl">
          {intl.formatMessage({
            id: 'Update_Recommended_Title',
            defaultMessage: 'Update recommended',
            description: ''
          })}
        </h1>

        <div>
          <p>
            {intl.formatMessage({
              id: 'Outdated_Version_Description',
              defaultMessage: 'You are using an outdated version of Notify+Assist.',
              description: ''
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'Update_Recommended_Description',
              defaultMessage: 'We recommend that you update to make use of the latest bug fixes and improvements.',
              description: ''
            })}
          </p>
        </div>

        <button onClick={openAppOrPlayStore}>
          <img src={OS === 'IOS' ? appStoreLogo : playStoreLogo} alt="App store" className="w-11 mx-auto" />
        </button>
      </div>
    </Drawer>
  )
}
