import React from 'react'
import styled from 'styled-components'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

const Container = styled.div`
  * {
    font-size: 11px;
  }
`

interface Props {
  volume: Array<{
    date: string | Date
    itemCount: number
    sessionCount: number
    value: number
  }>
}

const VolumeGraph = ({ volume }: Props) => {
  const { intl } = useTranslation()

  const resolveGraphData = () =>
    volume.map(({ date, itemCount, sessionCount, value }) => ({
      day: intl.formatDate(date, { day: '2-digit', weekday: 'short' }),
      itemCount,
      sessionCount,
      value: value / 1000
    }))

  return (
    <Container>
      <ResponsiveContainer height={300}>
        <BarChart data={resolveGraphData()} margin={{ top: 35, right: 35, bottom: 35 }}>
          <XAxis dataKey="day" height={35} />
          <YAxis axisLine={false} tickLine={false} minTickGap={70} />
          <Tooltip />
          <CartesianGrid vertical={false} strokeDasharray="3 0" />
          <Bar
            dataKey="value"
            fill="var(--colors-blue-light)"
            name={intl.formatMessage({
              id: 'Refund',
              defaultMessage: 'Refund',
              description: ''
            })}
          />
          <Bar
            dataKey="sessionCount"
            fill="var(--colors-green)"
            name={intl.formatMessage({
              id: 'Customers',
              defaultMessage: 'Customers',
              description: ''
            })}
          />
          <Bar
            dataKey="itemCount"
            fill="var(--colors-atlantic)"
            name={intl.formatMessage({
              id: 'Volume',
              defaultMessage: 'Volume',
              description: ''
            })}
          />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default VolumeGraph
