import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { ENV } from '../../lib'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    flex: 1;
  }

  > *:not(:last-child) {
    margin-bottom: var(--spacing-xl);
  }
`

const SelectContainer = styled.div`
  position: relative;
  margin-top: var(--spacing-lg);

  > label {
    position: absolute;
    top: -60%;
    font-weight: bold;
    left: var(--spacing-md);
  }

  > svg {
    position: absolute;
    top: 50%;
    right: var(--spacing-md);
    transform: translateY(-50%);
    pointer-events: none;
  }
`

const StyledSelect = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  appearance: none;
  border-radius: 100px;
  background-color: white;
  box-shadow: var(--shadow-sm);
  padding: var(--spacing-md) var(--spacing-xl) var(--spacing-md) var(--spacing-lg);
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: black;
  :focus {
    outline: none;
  }
`

const envToReadableMap = {
  au: 'Australia',
  eu: 'Europe',
  us: 'Americas'
}

export const EnvPickerDrawer = () => {
  const { intl } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [selectedEnv, setSelectedEnv] = useState<string>(ENV)

  const onClick = () => {
    setLoading(true)
    window.localStorage.setItem('ENV_OVERRIDE', selectedEnv)
    window.location.href = '/'
  }

  return (
    <Container>
      <h1 className="text-xl">
        {intl.formatMessage({
          id: 'Select_Region',
          defaultMessage: 'Select region',
          description: ''
        })}
      </h1>

      <SelectContainer>
        <label htmlFor="env">
          {intl.formatMessage({
            id: 'Region',
            defaultMessage: 'Region',
            description: 'Label for region select'
          })}
        </label>

        <StyledSelect id="env" value={selectedEnv} onChange={evt => setSelectedEnv(evt.target.value)}>
          {Object.keys(envToReadableMap)
            .sort()
            .map(env => (
              <option key={env} value={env}>
                {envToReadableMap[env]}
              </option>
            ))}
        </StyledSelect>
      </SelectContainer>

      <button onClick={onClick} className="btn btn-success w-full" disabled={loading} aria-disabled={loading}>
        {loading ? (
          <div className="loadingSpinner" />
        ) : (
          intl.formatMessage({
            id: 'Confirm',
            defaultMessage: 'Confirm',
            description: 'Confirm button'
          })
        )}
      </button>
    </Container>
  )
}
