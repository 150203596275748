import { authenticatedFetchData } from '../lib'

// TODO store-access-api: enrich payload with name and emails from KC
export function fetchStoreAccessRequests(storeId: string) {
  return authenticatedFetchData(`/store-access/v1.0/all-access-requests/${storeId}`).run()
}

export function fetchUserAccessRequests(): Promise<StoreAccessRequestType[]> {
  return authenticatedFetchData(`/store-access/v1.0/current-user`).run()
}

export function deleteAccount() {
  return authenticatedFetchData(`/store-access/v1.0/current-user`, {
    method: 'DELETE'
  }).run()
}

export function requestStoreAccess(storeId: string) {
  return authenticatedFetchData(`/store-access/v1.0/store/${storeId}/create-access-request`, {
    method: 'POST'
  }).run()
}

export function setStatusForAccessRequest(accessRequestId: string, status: StoreAccessRequestType['status']) {
  return authenticatedFetchData(`/store-access/v1.0/access-request/${accessRequestId}/set-status`, {
    method: 'POST',
    body: JSON.stringify({ data: { status } })
  }).run()
}
