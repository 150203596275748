import jwtDecode from 'jwt-decode'
import { logError } from '@tomra/datadog-browser-logging'
import { authentication } from './authentication'

export interface AccessToken {
  sub: string
  name: string
  email: string
  scoped_resource_access: string[]
  full_resource_access: string[]
}

export const decodeAccessToken = token => jwtDecode(token) as AccessToken

export interface RefreshToken {
  exp: number
  iat: number
}

export const decodeRefreshToken = token => jwtDecode(token) as RefreshToken

export function getNameFromJwt(_token: string = authentication.getToken()) {
  try {
    return decodeAccessToken(_token).name || ''
  } catch (ex: any) {
    logError(new Error('Could not extract .name from invalid JWT'), ex)
    return ''
  }
}

export function getEmailFromJwt(_token: string = authentication.getToken()) {
  try {
    return decodeAccessToken(_token).email || ''
  } catch (ex: any) {
    logError(new Error('Could not extract .email from invalid JWT'), ex)
    return ''
  }
}

export function getUserIdFromJwt(_token: string = authentication.getToken()) {
  try {
    return decodeAccessToken(_token).sub || ''
  } catch (ex: any) {
    logError(new Error('Could not extract .sub from invalid JWT'), ex)
    return ''
  }
}

export function getResourceAccessFromJwt(_token: string = authentication.getToken()) {
  try {
    const scopedResourceAccess = decodeAccessToken(_token).scoped_resource_access

    if (!Array.isArray(scopedResourceAccess) || scopedResourceAccess.length === 0) {
      throw new Error('Invalid scoped_resource_access found in token, got: ' + JSON.stringify(scopedResourceAccess))
    }

    const notifyAssistResource = scopedResourceAccess.find(
      resourceString => resourceString.indexOf('NotifyAssist') !== -1
    )

    if (!notifyAssistResource) {
      throw new Error('No N+A resource found in tokens scoped_resource_access')
    }

    const [product, role] = notifyAssistResource.split('#')
    return { product, role }
  } catch (ex: any) {
    logError(new Error('Could not extract resource access from invalid JWT'), ex)
    return { product: 'unknown', role: 'unknown' }
  }
}

export function getHasOtherProductsFromJwt(_token: string = authentication.getToken()) {
  const decodedToken = decodeAccessToken(_token)

  const hasFullResourceAccess =
    decodedToken.full_resource_access.filter(
      access => access !== 'manage-account' && access !== 'manage-account-links' && access !== 'view-profile'
    ).length > 0
  const hasOtherProducts =
    decodedToken.scoped_resource_access.filter(access => access.indexOf('NotifyAssist') === -1).length > 0

  return hasFullResourceAccess || hasOtherProducts
}
