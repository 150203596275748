import React from 'react'
import ReactDOM from 'react-dom'
import { CloseIcon } from '../icons'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

type Props = {
  children: React.ReactNode
  onClose: () => void
}

export const Dialog = ({ children, onClose }: Props) => {
  const { intl } = useTranslation()

  return ReactDOM.createPortal(
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-black-transparent flex items-center justify-center p-lg z-50">
      {onClose && (
        <button
          className="btn btn-icon absolute top-xl right-xl"
          aria-label={intl.formatMessage({
            id: 'Close',
            defaultMessage: 'Close',
            description: ''
          })}
          onClick={() => onClose()}
        >
          <CloseIcon width="2rem" height="2rem" color="white" />
        </button>
      )}
      {children}
    </div>,
    document.getElementById('root') as HTMLDivElement
  )
}
