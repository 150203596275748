import React from 'react'
import styled from 'styled-components'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { BinStatus } from '../../lib/rvmFlags'

const Indicator = styled.div<{ fillLevel: number; color: string }>`
  background: linear-gradient(
    0deg,
    ${props => props.color} ${props => props.fillLevel}%,
    var(--colors-grey) ${props => props.fillLevel}%
  );
`

interface Props {
  binStatus: BinStatusType[]
}

export const BinStatusIndicator = ({ binStatus }: Props) => {
  const { intl } = useTranslation()
  const hasBinStatus = binStatus.length > 0

  const colorAndFillLevelMap = {
    FULL: {
      color: 'var(--colors-red)',
      fillLevel: 100,
      label: intl.formatMessage(BinStatus.FULL)
    },
    SEMI_FULL: {
      color: 'var(--colors-yellow)',
      fillLevel: 75,
      label: intl.formatMessage(BinStatus.SEMI_FULL)
    },
    NOT_FULL: {
      color: 'var(--colors-black)',
      fillLevel: 15,
      label: intl.formatMessage(BinStatus.NOT_FULL)
    }
  }

  return (
    <div className="flex flex-col md:items-center bg-fog md:p-md py-md px-lg">
      {hasBinStatus ? intl.formatMessage(BinStatus.TITLE) : intl.formatMessage(BinStatus.UNKNOWN)}

      {hasBinStatus && (
        <div className="flex w-full mt-sm md:justify-center space-x-sm">
          {binStatus.map(({ binId, status }) => (
            <div className="flex flex-col items-center" key={parseInt(binId, 10)}>
              <Indicator
                className="rounded-half w-md h-xl"
                color={colorAndFillLevelMap[status].color}
                fillLevel={colorAndFillLevelMap[status].fillLevel}
              />
              <small>{binId}</small>
              <small className="sr-only">{colorAndFillLevelMap[status].label}</small>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
