import { signInWithCustomToken, onAuthStateChanged } from 'firebase/auth'
import { httpsCallable, type HttpsCallableResult } from 'firebase/functions'
import { logError } from '@tomra/datadog-browser-logging'
import { authentication, FIREBASE, ENV, STAGE } from '../lib'

const envFunctionMap = {
  eu: 'createCustomTokenEu',
  au: 'createCustomTokenAu',
  us: 'createCustomTokenUs'
}

export const initFirebaseAuth = async (approvedLocationId: string, onSuccess: () => void) => {
  return onAuthStateChanged(FIREBASE.auth, async user => {
    if (!authentication.getRefreshToken()) return // avoid createCustomToken on logout

    if (user) {
      const { claims } = await user.getIdTokenResult()

      // Consider already authenticated if token exists with same location
      // Otherwise re-auth with the new location
      if (claims.approvedLocationId === approvedLocationId) {
        onSuccess()
        return
      }
    }

    try {
      const createCustomToken = httpsCallable(FIREBASE.functions, envFunctionMap[ENV])
      const customTokenPayload = {
        token: authentication.getToken(),
        env: ENV,
        stage: STAGE,
        approvedLocationId
      }

      const response: HttpsCallableResult<unknown> = await new Promise(resolve => {
        createCustomToken(customTokenPayload).then(resolve, () => {
          const intervalRef = setInterval(() => {
            createCustomToken(customTokenPayload).then(
              response => {
                clearInterval(intervalRef)
                resolve(response)
              },
              (error: any) => {
                logError(new Error('Failed to create custom Firebase token'), error)
              }
            )
          }, 5000)
        })
      })

      await new Promise(resolve => {
        signInWithCustomToken(FIREBASE.auth, response?.data?.['customToken']).then(resolve, () => {
          const intervalRef = setInterval(() => {
            signInWithCustomToken(FIREBASE.auth, response?.data?.['customToken']).then(
              response => {
                clearInterval(intervalRef)
                resolve(response)
              },
              (error: any) => {
                logError(new Error('Failed to sign in to Firebase with custom token'), error)
              }
            )
          }, 5000)
        })
      })

      onSuccess()
    } catch (error: any) {
      logError(new Error('Failed to init Firebase auth'), error)
    }
  })
}
