import React, { useContext } from 'react'
import { RvmList } from './RvmList'
import { StoreAccessContext } from '../../contexts'
import { NotificationsUpdatedDrawer } from '../temporary/NotificationsUpdatedDrawer'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

export const StatusPage = () => {
  const { intl } = useTranslation()
  const { userAccessRequests } = useContext(StoreAccessContext)
  const approvedLocation = userAccessRequests.find(request => request.status === 'APPROVED')

  return (
    <>
      <NotificationsUpdatedDrawer />

      <h1 className="sr-only">
        {intl.formatMessage({
          id: 'Status',
          defaultMessage: 'Status',
          description: ''
        })}
      </h1>

      {approvedLocation?.installations?.length === 0 ? (
        <div className="alert alert-warning centerAbsolute">
          {intl.formatMessage({
            id: 'No_Installations',
            defaultMessage:
              'No reverse vending machines found in this location yet. They might show up in the future or you might not have access to any.',
            description: ''
          })}
        </div>
      ) : (
        <RvmList />
      )}
    </>
  )
}
