import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { EnvPickerButton } from './EnvPickerButton'
import { InstallAppDrawer } from '../InstallAppDrawer'
import { authentication, STAGE } from '../../lib'
import { setAndSaveAppUrl } from '../../services/react-native-service'
import reuseIntoUseCircle from '../../images/reuseIntoUseCircle.svg'
import tomraLogoBlack from '../../images/tomraLogoBlack.svg'

const rotateKeyframes = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`

const AnimatedImage = styled.img`
  animation: ${rotateKeyframes} 30s linear 0s infinite;
  width: 100%;
  height: 100%;
  will-change: transform;
`

export const WelcomePage = () => {
  const [easterEggClicks, setEasterEggClicks] = useState(1)

  const onBrandingImageClick = () => {
    setEasterEggClicks(easterEggClicks + 1)

    if (easterEggClicks % 15 === 0) {
      const isTest = STAGE === 'test'

      if (window.confirm(`Switch to ${isTest ? 'prod' : 'test'} environment?`)) {
        setAndSaveAppUrl(isTest ? window.location.host.replace('test.', '') : `test.${window.location.host}`)
      }
    }
  }

  return (
    <>
      <InstallAppDrawer />

      <div className="flex flex-col items-center justify-between h-screen p-xl max-w-25 mx-auto">
        <img className="h-lg mt-xl" src={tomraLogoBlack} alt="Tomra logo" />

        <div className="relative flex items-center justify-center h-17 w-17 mt-lg mb-md text-center">
          <AnimatedImage src={reuseIntoUseCircle} alt="Reuse into use" />

          <button onClick={onBrandingImageClick}>
            <h1 className="leading-none w-3/4 centerAbsolute text-xl font-bold">Welcome to Notify+Assist</h1>
          </button>
        </div>

        <div className="w-full space-y-md">
          <EnvPickerButton />

          <button onClick={authentication.login} className="btn btn-primary-dark w-full">
            Log in
          </button>
        </div>
      </div>
    </>
  )
}
