import React, { useContext, useState } from 'react'
import { StoreAccessContext, AppMessageContext } from '../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { daysSince } from '../../lib'
import { ampli } from '../../ampli'
import { AMPLITUDE_HIDE_CLASS_NAME } from '../../lib/amplitudeUtils'

interface Props {
  accessRequest: StoreAccessRequestType
}

export const UserRow = ({ accessRequest }: Props) => {
  const { intl } = useTranslation()
  const { setStatusForAccessRequest } = useContext(StoreAccessContext)
  const { pushMessage } = useContext(AppMessageContext)
  const { status, identity, accessRequestId } = accessRequest
  const { firstName, lastName, email, createdTimestamp } = identity || {}
  const [isLoading, setIsLoading] = useState(false)

  const updateStatusForAccessRequest = async (status: StoreAccessRequestType['status']) => {
    try {
      setIsLoading(true)
      await setStatusForAccessRequest(accessRequestId, status)

      ampli.accountDeactivated({ days_since_created: createdTimestamp ? daysSince(createdTimestamp) : undefined })

      setIsLoading(false)
    } catch (error) {
      pushMessage({
        formattedMessage: (
          <>
            {intl.formatMessage({
              id: 'RequestFailed',
              defaultMessage: 'Uh-oh, something went wrong. Please try again later.',
              description: ''
            })}
          </>
        ),
        type: 'danger',
        duration: 5000
      })

      setIsLoading(false)
    }
  }

  return (
    <tr>
      <td className={AMPLITUDE_HIDE_CLASS_NAME}>{`${firstName} ${lastName}`}</td>
      <td className={`hidden sm:table-cell ${AMPLITUDE_HIDE_CLASS_NAME}`}>{email}</td>
      <td className="hidden sm:table-cell">{createdTimestamp && <span>{intl.formatDate(createdTimestamp)}</span>}</td>

      {status === 'APPROVED' ? (
        <td>
          {isLoading ? (
            <div className="loadingSpinner" />
          ) : (
            <button className="btn btn-primary-danger w-full" onClick={() => updateStatusForAccessRequest('REVOKED')}>
              {intl.formatMessage({
                id: 'Deactivate_User',
                defaultMessage: 'Deactivate',
                description: ''
              })}
            </button>
          )}
        </td>
      ) : (
        <td>
          {isLoading ? (
            <div className="loadingSpinner" data-testid="loadingSpinner" />
          ) : (
            <div className="space-y-md">
              <button className="btn btn-primary-dark w-full" onClick={() => updateStatusForAccessRequest('APPROVED')}>
                {intl.formatMessage({
                  id: 'Approve_User',
                  defaultMessage: 'Approve',
                  description: ''
                })}
              </button>

              <button
                className="btn btn-primary-danger w-full"
                onClick={() => updateStatusForAccessRequest('DECLINED')}
              >
                {intl.formatMessage({
                  id: 'Decline_User',
                  defaultMessage: 'Decline',
                  description: ''
                })}
              </button>
            </div>
          )}
        </td>
      )}
    </tr>
  )
}
