import React, { ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'

type Props = {
  visible: boolean
  type: string
  children: ReactNode
}

export const Transition = ({ visible, type, children }: Props) => (
  <CSSTransition unmountOnExit appear in={visible} timeout={{ exit: 200 }} classNames={type}>
    {children}
  </CSSTransition>
)
