import React, { useState } from 'react'
import { RvmBadge } from './RvmBadge'
import { RvmInfo } from './RvmInfo'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { logError } from '@tomra/datadog-browser-logging'

type Props = {
  installation: InstallationType
}

export const RvmSmallSkeleton = () => {
  const { intl } = useTranslation()

  return (
    <div aria-label="rvm skeleton" className="animate-zoomIn-fast bg-white border-b border-grey flex flex-col">
      <div className="flex items-center justify-between space-x-sm m-lg">
        <div className="animate-pulse h-5 w-4 bg-[rgb(220,220,220)] rounded-half" />
        <div>
          <div className="animate-pulse px-md py-sm bg-[rgb(220,220,220)] text-center rounded-half" />

          <small>
            {intl.formatMessage({
              id: 'Awaiting_Event',
              defaultMessage: 'Awaiting event from machine...',
              description: ''
            })}
          </small>
        </div>
        <div className="animate-pulse h-3 w-3 bg-[rgb(220,220,220)] rounded"></div>
      </div>
    </div>
  )
}

export const RvmSmall = ({ installation }: Props) => {
  const [machineType, setMachineType] = useState(installation.machineType || 'UNKNOWN')
  const { currentStatus, name, rvmSerialNumber } = installation
  const detail = currentStatus && currentStatus.detail

  const _onImageLoadError = () => {
    logError(new Error(`Failed to load RVM image for machineType: ${machineType}`))
    setMachineType('UNKNOWN')
  }

  return (
    <div className="animate-zoomIn-fast bg-white border-b border-grey flex flex-col">
      <div className="flex items-center justify-between m-lg">
        <img alt="Rvm model" src={`/images/rvms/${machineType}.svg`} className="h-4" onError={_onImageLoadError} />

        <div className="text-center">
          <b className="block w-8 truncateText">{name}</b>
          <span>{rvmSerialNumber}</span>
        </div>

        <RvmBadge statusDetail={detail} small />
      </div>

      <RvmInfo installation={installation} />
    </div>
  )
}
