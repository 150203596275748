import React, { ReactNode } from 'react'
import * as service from '../services/store-access-service'
import { ampli } from '../ampli'
import { logError } from '@tomra/datadog-browser-logging'

export const StoreAccessContext = React.createContext<{
  userAccessRequests: StoreAccessRequestType[]
  storeAccessRequests: StoreAccessRequestType[]
  fetchUserAccessRequests: () => Promise<StoreAccessRequestType[]>
  fetchStoreAccessRequests: (locationId: string) => Promise<any>
  setStatusForAccessRequest: (accessRequestId: string, status: StoreAccessRequestType['status']) => Promise<any>
  requestStoreAccess: (storeId: string) => Promise<any>
}>({
  userAccessRequests: [],
  storeAccessRequests: [],
  fetchUserAccessRequests: () => {
    throw new Error('Function not provided')
  },
  fetchStoreAccessRequests: () => {
    throw new Error('Function not provided')
  },
  setStatusForAccessRequest: () => {
    throw new Error('Function not provided')
  },
  requestStoreAccess: () => {
    throw new Error('Function not provided')
  }
})

type Props = {
  children: ReactNode
}

type State = {
  userAccessRequests: StoreAccessRequestType[]
  storeAccessRequests: StoreAccessRequestType[]
}

export class StoreAccessProvider extends React.Component<Props, State> {
  state: State = {
    userAccessRequests: [],
    storeAccessRequests: []
  }

  _fetchUserAccessRequests = async () => {
    try {
      const userAccessRequests = await service.fetchUserAccessRequests()
      const approvedLocation = userAccessRequests.find(request => request.status === 'APPROVED')
      window.Tomra.currentLocationId = approvedLocation?.locationId
      this.setState({ userAccessRequests })
      return userAccessRequests
    } catch (error: any) {
      logError(new Error('Failed to fetch user access requests'), error)
      throw error
    }
  }

  _fetchStoreAccessRequests = async (locationId: string) => {
    try {
      const storeAccessRequests = await service.fetchStoreAccessRequests(locationId)
      this.setState({ storeAccessRequests })
    } catch (error: any) {
      logError(new Error('Failed to fetch store access requests'), error)
      throw error
    }
  }

  _setStatusForAccessRequest = async (accessRequestId: string, status: StoreAccessRequestType['status']) => {
    try {
      await service.setStatusForAccessRequest(accessRequestId, status)

      this.setState(prevState => ({
        storeAccessRequests: prevState.storeAccessRequests.map(accessRequest => {
          if (accessRequest.accessRequestId === accessRequestId) {
            return { ...accessRequest, status: status }
          }
          return accessRequest
        }),
        userAccessRequests: prevState.userAccessRequests.map(accessRequest => {
          if (accessRequest.accessRequestId === accessRequestId) {
            return { ...accessRequest, status: status }
          }
          return accessRequest
        })
      }))
    } catch (error: any) {
      logError(new Error('Failed to set status for store access request'), error)
      throw error
    }
  }

  _requestStoreAccess = async (storeId: string) => {
    try {
      const accessRequest = await service.requestStoreAccess(storeId)
      ampli.accessRequested()
      this.setState({ userAccessRequests: [accessRequest] })
    } catch (error: any) {
      logError(new Error('Failed to request store access'), error)
      throw error
    }
  }

  render() {
    const { userAccessRequests, storeAccessRequests } = this.state

    return (
      <StoreAccessContext.Provider
        value={{
          userAccessRequests,
          storeAccessRequests,
          fetchUserAccessRequests: this._fetchUserAccessRequests,
          fetchStoreAccessRequests: this._fetchStoreAccessRequests,
          setStatusForAccessRequest: this._setStatusForAccessRequest,
          requestStoreAccess: this._requestStoreAccess
        }}
      >
        {this.props.children}
      </StoreAccessContext.Provider>
    )
  }
}
