import React, { useContext, useEffect, useState } from 'react'
import { AppMessageContext } from '../contexts/AppMessageContext'

export const AppMessages = () => {
  const { messages, removeMessageById } = useContext(AppMessageContext)
  const [currentMessage, setCurrentMessage] = useState<AppMessageType>()

  useEffect(() => {
    const latestMessage = messages[messages.length - 1]
    setCurrentMessage(latestMessage)

    if (latestMessage && latestMessage.duration) {
      const timeout = window.setTimeout(() => {
        removeMessageById(latestMessage.formattedMessage.props.id)
      }, latestMessage.duration)

      return () => window.clearTimeout(timeout)
    }
  }, [messages, removeMessageById])

  return currentMessage ? (
    <div
      className={`fixed bottom-0 z-10 max-w-30 left-0 m-lg alert ${
        currentMessage.type && `alert-${currentMessage.type}`
      }`}
    >
      <p>{currentMessage.formattedMessage}</p>
    </div>
  ) : null
}
