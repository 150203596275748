import React from 'react'
import {
  AppMessageProvider,
  InstallationsProvider,
  StoreAccessProvider,
  StatisticsProvider,
  NotificationsProvider
} from './'

type Props = {
  children: React.ReactNode
}

export const ContextProvider = ({ children }: Props) => (
  <StoreAccessProvider>
    <AppMessageProvider>
      <InstallationsProvider>
        <StatisticsProvider>
          <NotificationsProvider>{children}</NotificationsProvider>
        </StatisticsProvider>
      </InstallationsProvider>
    </AppMessageProvider>
  </StoreAccessProvider>
)
