import React, { useEffect, useState } from 'react'
import { useRouteError } from 'react-router-dom'
import { logInfo } from '@tomra/datadog-browser-logging'
import { ErrorIcon, ConnectionDownIcon } from './icons'
import { authentication } from '../lib'
import { type FallbackProps } from 'react-error-boundary'

const ErrorFallback = ({ errorMsg, onReset }: { errorMsg: string; onReset: () => void }) => {
  const [isLoading, setIsLoading] = useState(false)

  const onResetButton = () => {
    setIsLoading(true)
    onReset()
  }

  return (
    <div className="min-h-[100vh] bg-yellow flex flex-col items-center justify-around p-xl text-center">
      {errorMsg.match(/(XHR error)|(failed to fetch)|(fetch error)|(aborted)|(request failed)|(load failed)/gi) ? (
        <>
          <ConnectionDownIcon width="100" height="100" />
          <p>Seems we have some trouble fetching your data. Please check your internet connection, and try again.</p>
        </>
      ) : (
        <>
          <ErrorIcon width="100" height="100" />
          <p>Whoopsie! Something went wrong. Please try again later.</p>
          <p className="p-md rounded-half bg-red-transparent text-sm w-full">{errorMsg}</p>
        </>
      )}

      <button className="btn btn-primary-light w-full" onClick={onResetButton}>
        {isLoading ? <div className="loadingSpinner" /> : <span>Reload</span>}
      </button>
    </div>
  )
}

export const onGeneralErrorBoundary = error => {
  const errorMsg = typeof error === 'string' ? error : error?.message
  logInfo('General Error Boundary shown', { errorMsg })

  if (errorMsg.includes('Unsuccessful HTTP response: 401')) {
    logInfo('Signing out due to 401', {
      errorMsg
    })

    authentication.logout()
  }
}

export const GeneralErrorBoundary = ({ error, resetErrorBoundary }: FallbackProps) => {
  const errorMsg = typeof error === 'string' ? error : error?.message
  return <ErrorFallback errorMsg={errorMsg} onReset={resetErrorBoundary} />
}

export const RouterErrorBoundary = () => {
  const error = useRouteError() as any
  const errorMsg = typeof error === 'string' ? error : error?.message

  useEffect(() => {
    logInfo('Router Error Boundary shown', { errorMsg })

    if (errorMsg.includes('Unsuccessful HTTP response: 401')) {
      logInfo('Signing out due to 401', {
        errorMsg
      })

      authentication.logout()
    }
  }, [errorMsg])

  return <ErrorFallback errorMsg={errorMsg} onReset={() => (window.location.href = '/')} />
}
