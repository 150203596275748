import React, { useEffect, useState } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { deleteAccount } from '../../services/store-access-service'
import { authentication, getHasOtherProductsFromJwt } from '../../lib'
import { Dialog } from '../common'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { ampli } from '../../ampli'

export const ConfirmDeleteAccountDialog = ({ onClose }: { onClose: () => void }) => {
  const { intl } = useTranslation()
  const [isDeletingAccount, setIsDeletingAccount] = useState(false)
  const [checkboxAccepted, setCheckboxAccepted] = useState(false)
  const [showFailureMessage, setShowFailureMessage] = useState(false)

  const hasOtherProducts = getHasOtherProductsFromJwt()

  useEffect(() => {
    if (showFailureMessage) {
      const timeoutHandle = window.setTimeout(() => setShowFailureMessage(false), 3000)
      return () => window.clearTimeout(timeoutHandle)
    }
  }, [showFailureMessage])

  const _deleteAccount = () => {
    setIsDeletingAccount(true)
    deleteAccount()
      .then(() => {
        ampli.accountDeleted()
        authentication.logout()
      })
      .catch(e => {
        setIsDeletingAccount(false)
        setShowFailureMessage(true)
        logError(new Error('Failed to delete account'), e)
      })
  }

  return (
    <Dialog onClose={onClose}>
      <div className="card flex flex-col">
        {showFailureMessage ? (
          <div className="alert alert-danger">
            {intl.formatMessage({
              id: 'Delete_User_Failed',
              defaultMessage: 'Something went wrong! Failed to delete user.',
              description: ''
            })}
          </div>
        ) : (
          <div className="space-y-lg m-auto p-lg">
            <h1 className="text-xl">
              {intl.formatMessage({
                id: 'Delete_User',
                defaultMessage: 'Delete your user',
                description: ''
              })}
            </h1>
            {hasOtherProducts ? (
              <p>
                {intl.formatMessage(
                  {
                    id: 'Delete_User_Has_Other_Products',
                    defaultMessage:
                      'There are additional products associated with your user other than Notify+Assist, and it can therefore not be deleted from within the Notify+Assist app. Contact {supportEmail} to delete your user.',
                    description: ''
                  },
                  {
                    supportEmail: (
                      <a className="link" href="mailto:marketsupport.tomraproducts@tomra.com">
                        marketsupport.tomraproducts@tomra.com
                      </a>
                    )
                  }
                )}
              </p>
            ) : (
              <React.Fragment>
                <p>
                  {intl.formatMessage({
                    id: 'Delete_User_Disclaimer',
                    defaultMessage:
                      "This will permanently delete your Notify+Assist user. A deleted user can't be recovered.",
                    description: ''
                  })}
                </p>
                <div className="flex items-start space-x-sm">
                  <input
                    id="delete-account-disclaimer-checkbox"
                    type="checkbox"
                    className="checkbox"
                    checked={checkboxAccepted}
                    onChange={() => setCheckboxAccepted(!checkboxAccepted)}
                  />
                  <label htmlFor="delete-account-disclaimer-checkbox">
                    {intl.formatMessage({
                      id: 'Delete_User_Checkbox_Label',
                      defaultMessage: 'I understand that my user will be permanently deleted',
                      description: ''
                    })}
                  </label>
                </div>
                {isDeletingAccount ? (
                  <div className="loadingSpinner" />
                ) : (
                  <button
                    className="btn btn-primary-danger w-full"
                    disabled={!checkboxAccepted}
                    onClick={_deleteAccount}
                  >
                    {intl.formatMessage({
                      id: 'Delete_User',
                      defaultMessage: 'Delete your user',
                      description: ''
                    })}
                  </button>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </Dialog>
  )
}
