import React, { useContext, useEffect, useState } from 'react'
import { UsersTable } from './UsersTable'
import { StoreAccessContext } from '../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

type ActiveListType = 'APPROVED' | 'PENDING'

export const UsersPage = () => {
  const { intl } = useTranslation()
  const { userAccessRequests, storeAccessRequests, fetchStoreAccessRequests } = useContext(StoreAccessContext)
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>(
    storeAccessRequests.length === 0 ? 'loading' : ''
  )
  const [activeList, setActiveList] = useState<ActiveListType>('APPROVED')

  useEffect(() => {
    fetchStoreAccessRequests(locationId).then(
      () => setRequestStatus(''),
      () => setRequestStatus('failed')
    )
  }, [fetchStoreAccessRequests])

  const approvedStoreAccessRequests = storeAccessRequests.filter(accessRequest => accessRequest.status === 'APPROVED')
  const pendingStoreAccessRequests = storeAccessRequests.filter(accessRequest => accessRequest.status === 'PENDING')

  if (requestStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" data-testid="loadingSpinner" />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center p-lg">
      <h1 className="sr-only">
        {intl.formatMessage({
          id: 'Users',
          defaultMessage: 'Users',
          description: ''
        })}
      </h1>

      {requestStatus === 'failed' ? (
        <div className="centerAbsolute alert alert-danger">
          {intl.formatMessage({
            id: 'Fetch_Store_Access_Requests_Failed',
            defaultMessage:
              'Uh-oh, seems we were unable to fetch the access requests for this store at the moment. Please try again later.',
            description: ''
          })}
        </div>
      ) : (
        <React.Fragment>
          <div className="tabs w-full">
            <button
              onClick={() => setActiveList('PENDING')}
              className={activeList === 'PENDING' ? 'tab active' : 'tab'}
            >
              {intl.formatMessage(
                {
                  id: 'Pending_Access_Requests',
                  defaultMessage: 'Pending ({count})',
                  description: ''
                },
                { count: pendingStoreAccessRequests.length }
              )}
            </button>
            <button
              onClick={() => setActiveList('APPROVED')}
              className={activeList === 'APPROVED' ? 'tab active' : 'tab'}
            >
              {intl.formatMessage(
                {
                  id: 'Approved_Access_Requests',
                  defaultMessage: 'Approved ({count})',
                  description: ''
                },
                { count: approvedStoreAccessRequests.length }
              )}
            </button>
          </div>

          <UsersTable
            storeAccessRequests={activeList === 'APPROVED' ? approvedStoreAccessRequests : pendingStoreAccessRequests}
          />
        </React.Fragment>
      )}
    </div>
  )
}
