import React from 'react'
import { badgeColorAndIconMap } from './badgeColorAndIconMap'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

interface Props {
  statusDetail: string
  colorSetting?: 'normal' | 'inverted'
  small?: boolean
  className?: string
}

export const RvmBadge = ({ statusDetail, colorSetting = 'normal', small, className = '' }: Props) => {
  const { intl } = useTranslation()
  const Icon = badgeColorAndIconMap[statusDetail]?.icon.src || badgeColorAndIconMap.UNKNOWN.icon.src

  const backgroundColor =
    badgeColorAndIconMap[statusDetail]?.colorSetting[colorSetting]?.backgroundColor ||
    badgeColorAndIconMap.UNKNOWN.colorSetting[colorSetting].backgroundColor

  const iconColor =
    badgeColorAndIconMap[statusDetail]?.colorSetting[colorSetting]?.iconColor ||
    badgeColorAndIconMap.UNKNOWN.colorSetting[colorSetting].iconColor

  const labelText = badgeColorAndIconMap[statusDetail]?.labelText || badgeColorAndIconMap.UNKNOWN.labelText

  const size = badgeColorAndIconMap[statusDetail]?.icon.size || 25

  return small ? (
    <div
      className={`w-3 h-3 min-w-3 min-h-3 rounded flex items-center justify-center mr-md ${className}`}
      style={{ backgroundColor }}
    >
      <Icon aria-label={intl.formatMessage(labelText)} color={iconColor} width={size} height={size} />
    </div>
  ) : (
    <div
      className={`rounded flex items-center justify-center absolute -left-lg bottom-xl w-4 h-4 ${className}`}
      style={{ backgroundColor }}
    >
      <Icon aria-label={intl.formatMessage(labelText)} color={iconColor} width={size} height={size} />
    </div>
  )
}
