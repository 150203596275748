export const rvmColorMap = {
  FRONT_DOOR_OPEN: 'bg-red-transparent',
  EMERGENCY: 'bg-red-transparent',
  STANDBY: 'bg-red-transparent',
  EMPTYING_BIN: 'bg-red-transparent',
  FULL_BIN: 'bg-red-transparent',
  FULL_TABLE: 'bg-red-transparent',
  CLOSED: 'bg-red-transparent',
  FULL_CONVEYOR: 'bg-red-transparent',
  PRINTER_OUT_OF_PAPER: 'bg-red-transparent',
  PRINTER_STORE_RESOLVABLE: 'bg-red-transparent',
  PRINTER_OTHER: 'bg-red-transparent',
  ERROR_BACKROOM: 'bg-red-transparent',
  ERROR_OTHER: 'bg-red-transparent',
  CLOSED_UPDATE: 'bg-red-transparent',
  TEMPORARY_CLOSED: 'bg-red-transparent',
  TEMPORARY_CLOSED_REAR_DOOR_OPEN: 'bg-red-transparent',
  TEMPORARY_CLOSED_MACHINE_BEING_EMPTIED: 'bg-red-transparent',
  TEMPORARY_CLOSED_RESET_BUTTON: 'bg-red-transparent',
  TEMPORARY_CLOSED_NEEDS_EMPTYING: 'bg-red-transparent',
  TEMPORARY_CLOSED_BACKROOM_NOT_READY: 'bg-red-transparent',
  BIN_STATUS_SEMI_FULL: 'bg-yellow-transparent',
  BIN_STATUS_FULL: 'bg-yellow-transparent',
  TABLE_SEMI_FULL: 'bg-yellow-transparent',
  CRATE_CONVEYOR_SEMI_FULL: 'bg-yellow-transparent',
  MACHINE_OFFLINE: 'bg-yellow-transparent',
  UNKNOWN: 'bg-red-transparent'
}
