import React, { ReactNode } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import { createBinStatus, createStatus } from '../lib'
import { type Unsubscribe } from 'firebase/firestore'
import { updateUserDoc, subscribeToInstallationsCollection, subscribeToUserCollection } from '../services'

export const InstallationsContext = React.createContext<{
  installations: InstallationType[]
  setupInstallationsListener: (approvedLocation: StoreAccessRequestType) => void
  updateInstallationPositions: (locationId: string, index: number, draggedIndex: number) => Promise<any>
  updateInstallationName: (ocationId: string, installationId: string, newName: string) => Promise<any>
}>({
  installations: [],
  setupInstallationsListener: () => {},
  updateInstallationPositions: () => {
    throw new Error('Function not provided')
  },
  updateInstallationName: () => {
    throw new Error('Function not provided')
  }
})

type Props = {
  children: ReactNode
}

type State = {
  installations: InstallationType[]
}

export class InstallationsProvider extends React.Component<Props, State> {
  _unsubscribeInstallationNamePosition?: Unsubscribe
  _unsubscribeInstallationStatus?: Unsubscribe

  state: State = {
    installations: []
  }

  _updateInstallationPositions = async (locationId: string, index: number, draggedIndex: number) => {
    const { installations } = this.state

    const isDraggedRightAndDropIndexIsNotLast = index > draggedIndex && index !== installations.length - 1

    const newInstallations = installations.slice()
    newInstallations.splice(
      isDraggedRightAndDropIndexIsNotLast ? index - 1 : index,
      0,
      newInstallations.splice(draggedIndex, 1)[0]
    )

    this.setState({ installations: newInstallations })

    try {
      await Promise.all(
        newInstallations.map(({ installationId }, index) =>
          updateUserDoc(locationId, { position: index }, `installations/${installationId}`)
        )
      )
    } catch (error: any) {
      logError(new Error('Failed to update installation position'), error)
      throw error
    }
  }

  _updateInstallationName = async (locationId: string, installationId: string, newName: string) => {
    try {
      await updateUserDoc(locationId, { name: newName }, `installations/${installationId}`)
    } catch (error: any) {
      logError(new Error('Failed to update installation name'), error)
      throw error
    }
  }

  _setupNamePositionListener = (approvedLocation: StoreAccessRequestType) => {
    if (!this._unsubscribeInstallationNamePosition) {
      this._unsubscribeInstallationNamePosition = subscribeToUserCollection(
        approvedLocation.locationId,
        (data: any[]) => {
          if (data.length > 0) {
            this.setState(prevState => ({
              installations: prevState.installations.map(installation => {
                const match = data.find(
                  firestoreInstallation => firestoreInstallation.id === installation.installationId
                )

                return match
                  ? {
                      ...installation,
                      name: match.name || installation.name,
                      position: match.position || installation.position
                    }
                  : installation
              })
            }))
          }
        },
        (error?: any) => {
          if (error) logError(new Error('Failed to subscribe to user collection from Firestore'), error)
        },
        'installations'
      )
    }
  }

  _setupStatusListener = (approvedLocation: StoreAccessRequestType) => {
    if (!this._unsubscribeInstallationNamePosition) {
      this._unsubscribeInstallationStatus = subscribeToInstallationsCollection(
        approvedLocation.locationId,
        (data: any[]) => {
          if (data.length > 0) {
            this.setState(prevState => {
              const approvedLocationInstallations = approvedLocation.installations || []

              const updatedInstallations = data
                .filter(firestoreInstallation => approvedLocationInstallations.includes(firestoreInstallation.id))
                .map(firestoreInstallation => {
                  const status = JSON.parse(firestoreInstallation.status)
                  const rvmSerialNumber =
                    firestoreInstallation.rvmSerialNumber === 'null' ? '' : firestoreInstallation.rvmSerialNumber
                  const machineType =
                    firestoreInstallation.machineType === 'null' ? 'UNKNOWN' : firestoreInstallation.machineType

                  const existingInstallation = prevState.installations.find(
                    installation => installation.installationId === firestoreInstallation.id
                  )

                  const binStatus = createBinStatus(status.binStatus)
                  const currentStatus = createStatus(status.flags, status.online)

                  return {
                    ...status,
                    installationId: firestoreInstallation.id,
                    name: existingInstallation?.name || machineType,
                    position: existingInstallation?.position || 0,
                    binStatus,
                    currentStatus,
                    rvmSerialNumber,
                    machineType
                  }
                })

              return {
                installations: updatedInstallations
              }
            })
          }
        },
        (error?: any) => {
          if (error) logError(new Error('Failed to subscribe to installations collection from Firestore'), error)
        }
      )
    }
  }

  _setupInstallationsListener = (approvedLocation: StoreAccessRequestType) => {
    this._setupStatusListener(approvedLocation)
    this._setupNamePositionListener(approvedLocation)
  }

  componentWillUnmount() {
    this._unsubscribeInstallationNamePosition?.()
    this._unsubscribeInstallationStatus?.()
  }

  render() {
    return (
      <InstallationsContext.Provider
        value={{
          installations: this.state.installations,
          setupInstallationsListener: this._setupInstallationsListener,
          updateInstallationPositions: this._updateInstallationPositions,
          updateInstallationName: this._updateInstallationName
        }}
      >
        {this.props.children}
      </InstallationsContext.Provider>
    )
  }
}
