import React, { ReactNode, useCallback, useState, useEffect } from 'react'

export const AppMessageContext = React.createContext<{
  messages: AppMessageType[]
  pushMessage: (message: AppMessageType) => void
  removeMessageById: (id: string) => void
}>({
  messages: [],
  pushMessage: () => {
    throw new Error('Function not provided')
  },
  removeMessageById: () => {
    throw new Error('Function not provided')
  }
})

export const AppMessageProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<AppMessageType[]>([])
  const { pathname, search } = window.location

  useEffect(() => {
    setMessages([])
  }, [pathname, search])

  const pushMessage = useCallback((message: AppMessageType) => {
    setMessages(messages => {
      const exists = messages.some(
        existingMessage => existingMessage.formattedMessage.props.id === message.formattedMessage.props.id
      )
      if (!exists) {
        return [...messages, message]
      }
      return messages
    })
  }, [])

  const removeMessageById = (id: string) => {
    const exists = messages.some(existingMessage => existingMessage.formattedMessage.props.id === id)
    if (exists) {
      setMessages(messages.filter(message => message.formattedMessage.props.id !== id))
    }
  }

  return (
    <AppMessageContext.Provider
      value={{
        messages,
        pushMessage,
        removeMessageById
      }}
    >
      {children}
    </AppMessageContext.Provider>
  )
}
