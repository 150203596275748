import React, { useContext, useState } from 'react'
import { AppMessageContext, InstallationsContext, StoreAccessContext } from '../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

interface Props {
  draggedIndex: number
  index: number
}

export const DropArea = ({ draggedIndex, index }: Props) => {
  const { intl } = useTranslation()
  const { updateInstallationPositions } = useContext(InstallationsContext)
  const { userAccessRequests } = useContext(StoreAccessContext)
  const { pushMessage } = useContext(AppMessageContext)
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''
  const isValidDropPosition = index !== draggedIndex && index !== draggedIndex + 1

  const _showDropArea = () => {
    if (isValidDropPosition) {
      setIsDraggedOver(true)
    }
  }

  const _hideDropArea = () => {
    if (isValidDropPosition) {
      setIsDraggedOver(false)
    }
  }

  const _onDrop = () => {
    setIsDraggedOver(false)

    if (isValidDropPosition) {
      updateInstallationPositions(locationId, index, draggedIndex).catch(() => {
        pushMessage({
          formattedMessage: (
            <>
              {intl.formatMessage({
                id: 'Update_Position_Failed',
                defaultMessage:
                  'Whoopsie! Seems we were unable to properly save your changes at this time. Please try again later.',
                description: ''
              })}
            </>
          ),
          type: 'danger',
          duration: 5 * 1000
        })
      })
    }
  }

  return (
    <div
      data-testid={`droparea-${index}`}
      className={`p-xs m-md flex-start h-19 border-grey border-l-2 border-r-2 transition-opacity duration-200 ${
        isDraggedOver ? 'opacity-1' : 'opacity-0'
      }`}
      onDrop={_onDrop}
      onDragOver={evt => evt.preventDefault()}
      onDragEnter={_showDropArea}
      onDragLeave={_hideDropArea}
    />
  )
}
