import React, { useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import LanguageSelect from './LanguageSelect'
import { authentication, getEmailFromJwt, getNameFromJwt, getResourceAccessFromJwt } from '../../lib'
import { LogoutIcon, SettingsIcon, LockIcon } from './../icons'
import tomraArrow from '../../images/tomra-arrow.png'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { ampli } from '../../ampli'
import { AMPLITUDE_HIDE_CLASS_NAME, getAmplitudeEmailType, getAmplitudeChain } from '../../lib/amplitudeUtils'

export const Header = () => {
  const { intl } = useTranslation()
  const { product, role } = getResourceAccessFromJwt()

  useEffect(() => {
    ampli.identify(undefined, {
      user_role: role,
      chain: getAmplitudeChain(getEmailFromJwt()),
      email_type: getAmplitudeEmailType(getEmailFromJwt())
    })
  }, [])

  return (
    <>
      <div className="navbar">
        <div className="max-w-screen-2xl w-full mx-auto grid grid-cols-12">
          <div className="flex items-center col-span-6 md:col-span-3">
            <img src={tomraArrow} className="h-xl" alt="TOMRA logo" />
            <p className={`ml-md ${AMPLITUDE_HIDE_CLASS_NAME}`}>{getNameFromJwt()}</p>
          </div>

          <div className="navbar fixed left-0 bottom-0 z-[1] flex justify-center !px-0 md:static md:col-span-6">
            <NavLink to="/" className="navtab flex-1">
              {intl.formatMessage({
                id: 'Status',
                defaultMessage: 'Status',
                description: ''
              })}
            </NavLink>

            <NavLink to="statistics" className="navtab flex-1">
              {product !== 'NotifyAssistPremium' && <LockIcon title="" width="1rem" height="1rem" color="white" />}
              {intl.formatMessage({
                id: 'Statistics',
                defaultMessage: 'Statistics',
                description: ''
              })}
            </NavLink>

            {role === 'StoreManager' && (
              <NavLink to="users" className="navtab flex-1">
                {intl.formatMessage({
                  id: 'Users',
                  defaultMessage: 'Users',
                  description: ''
                })}
              </NavLink>
            )}
          </div>

          <div className="col-span-6 md:col-span-3 flex items-center justify-end">
            <LanguageSelect />

            <Link to="settings/general" className="btn btn-icon" aria-label="settings">
              <SettingsIcon color="white" width="2rem" height="2rem" />
            </Link>

            <button
              className="btn btn-icon"
              onClick={authentication.logout}
              aria-label={intl.formatMessage({
                id: 'Logout',
                defaultMessage: 'Log out',
                description: ''
              })}
            >
              <LogoutIcon color="white" width="2rem" height="2rem" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
