import { RVMFlag, UIFlag } from './rvmFlags'

export function createStatus(flags: StatusFlagType[], online: boolean) {
  if (!online) {
    return { detail: UIFlag.MACHINE_OFFLINE.id }
  }

  const machineDownFlag = flags.find(flag => flag.type === RVMFlag.MACHINE_DOWN.id)
  if (machineDownFlag) {
    return { detail: machineDownFlag.detail, updatedTime: machineDownFlag.updatedTime }
  }

  const binStatusFullFlags = flags.filter(flag => flag.type === RVMFlag.BIN_STATUS_FULL.id)
  if (binStatusFullFlags.length !== 0) {
    return { detail: RVMFlag.BIN_STATUS_FULL.id }
  }

  const binStatusSemiFullFlags = flags.filter(flag => flag.type === RVMFlag.BIN_STATUS_SEMI_FULL.id)
  if (binStatusSemiFullFlags.length !== 0) {
    return { detail: RVMFlag.BIN_STATUS_SEMI_FULL.id }
  }

  const tableSemiFullFlag = flags.find(flag => flag.type === RVMFlag.TABLE_SEMI_FULL.id)
  if (tableSemiFullFlag) {
    return { detail: RVMFlag.TABLE_SEMI_FULL.id }
  }

  const crateConveyorSemiFullFlag = flags.find(flag => flag.type === RVMFlag.CRATE_CONVEYOR_SEMI_FULL.id)
  if (crateConveyorSemiFullFlag) {
    return { detail: RVMFlag.CRATE_CONVEYOR_SEMI_FULL.id }
  }

  // Other flag types
  if (flags[0] && flags[0].detail) {
    return { detail: flags[0].detail, updatedTime: flags[0].updatedTime }
  }

  // All good
  return { detail: UIFlag.MACHINE_HEALTHY.id }
}
