import React, { useContext, useEffect, useState } from 'react'
import tomraLogo from '../../images/tomralogo.png'
import { StoreAccessContext, AppMessageContext } from '../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { authentication } from '../../lib'
import { LogoutIcon } from '../icons'
import { ampli } from '../../ampli'

const fiveSeconds = 5000

export const RequestStoreAccess = () => {
  const { intl } = useTranslation()
  const { userAccessRequests, fetchUserAccessRequests, requestStoreAccess, setStatusForAccessRequest } =
    useContext(StoreAccessContext)
  const { pushMessage } = useContext(AppMessageContext)
  const [storeId, setStoreId] = useState('')
  const pendingAccessRequest = userAccessRequests.find(request => request.status === 'PENDING')

  const _requestStoreAccess = async () => {
    try {
      await requestStoreAccess(storeId)
    } catch (error: any) {
      if (error.status === 404) {
        pushMessage({
          formattedMessage: (
            <>
              {intl.formatMessage({
                id: 'RequestStoreAccess_UnknownStore',
                defaultMessage:
                  'The store you requested access to does not exist. Please check with your store manager, and try again.',
                description: ''
              })}
            </>
          ),
          type: 'warning',
          duration: fiveSeconds
        })
      } else {
        pushMessage({
          formattedMessage: (
            <>
              {intl.formatMessage({
                id: 'RequestFailed',
                defaultMessage: 'Uh-oh, something went wrong. Please try again later.',
                description: ''
              })}
            </>
          ),
          type: 'danger',
          duration: fiveSeconds
        })
      }
    }
  }

  const _cancelRequest = async () => {
    try {
      await setStatusForAccessRequest(pendingAccessRequest?.accessRequestId || '', 'CANCELLED')
    } catch (error) {
      pushMessage({
        formattedMessage: (
          <>
            {intl.formatMessage({
              id: 'RequestFailed',
              defaultMessage: 'Uh-oh, something went wrong. Please try again later.',
              description: ''
            })}
          </>
        ),
        type: 'danger',
        duration: fiveSeconds
      })
    }
  }

  useEffect(() => {
    ampli.requestAccessViewed()
  }, [])

  useEffect(() => {
    const intervalRef = window.setInterval(fetchUserAccessRequests, fiveSeconds)
    return () => window.clearInterval(intervalRef)
  }, [fetchUserAccessRequests])

  return (
    <>
      <button
        className="btn btn-icon absolute top-md right-md"
        onClick={authentication.logout}
        aria-label={intl.formatMessage({
          id: 'Logout',
          defaultMessage: 'Log out',
          description: ''
        })}
      >
        <LogoutIcon width="2rem" height="2rem" />
      </button>

      <div className="p-md flex flex-col items-center justify-center h-screen">
        <div className="card p-xl space-y-lg max-w-25">
          <img src={tomraLogo} alt="Tomra logo" className="w-12 m-auto" />

          <h1 className="text-lg font-bold">
            {intl.formatMessage({
              id: 'Welcome_Title',
              defaultMessage: 'Welcome to Notify+Assist',
              description: ''
            })}
          </h1>

          <div className="flex flex-col items-center space-y-xl">
            <>
              {pendingAccessRequest ? (
                <>
                  {intl.formatMessage({
                    id: 'Access_Not_Yet_Granted_By_Store_Manager',
                    defaultMessage:
                      'Access needs to be granted by your store manager before you can continue. Please wait.',
                    description: ''
                  })}

                  <div className="loadingSpinner mt-lg" />
                </>
              ) : (
                <p>
                  {intl.formatMessage({
                    id: 'Contact_Store_Manager_For_Store_Id',
                    defaultMessage: 'Please contact your store manager and ask for the store ID to request access.',
                    description: ''
                  })}
                </p>
              )}
            </>

            <>
              <form
                onSubmit={evt => {
                  evt.preventDefault()
                  pendingAccessRequest ? _cancelRequest() : _requestStoreAccess()
                }}
                className="w-full space-y-lg"
              >
                {pendingAccessRequest ? (
                  <button className="btn btn-primary-danger w-full">
                    {intl.formatMessage({
                      id: 'Cancel',
                      defaultMessage: 'Cancel',
                      description: ''
                    })}
                  </button>
                ) : (
                  <>
                    <div>
                      <label htmlFor="storeId" className="label block mb-xs">
                        {intl.formatMessage({
                          id: 'Store_Id',
                          defaultMessage: 'Store ID',
                          description: ''
                        })}
                      </label>
                      <input
                        id="storeId"
                        type="text"
                        value={storeId}
                        title={intl.formatMessage({
                          id: 'Store_Id_Required',
                          defaultMessage: 'Please enter a store ID',
                          description: ''
                        })}
                        onChange={evt => setStoreId(evt.target.value)}
                        required
                      />
                    </div>
                    <button className="btn btn-primary-dark mt-sm w-full" type="submit">
                      {intl.formatMessage({
                        id: 'Request_Store_Access',
                        defaultMessage: 'Request access',
                        description: ''
                      })}
                    </button>
                  </>
                )}
              </form>
            </>
          </div>
        </div>
      </div>
    </>
  )
}
