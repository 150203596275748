import React, { ReactNode, useState, useCallback } from 'react'
import { logError } from '@tomra/datadog-browser-logging'
import * as service from '../services/statistics-service'

export const StatisticsContext = React.createContext<{
  statistics?: StatisticsType
  fetchStatistics: (locationId: string) => Promise<any>
}>({
  fetchStatistics: () => {
    throw new Error('Function not provided')
  }
})

export const StatisticsProvider = ({ children }: { children: ReactNode }) => {
  const [statistics, setStatistics] = useState<StatisticsType>()

  const fetchStatistics = useCallback(async (locationId: string) => {
    try {
      const result = await service.fetchStatistics(locationId)
      setStatistics(result)
    } catch (error: any) {
      logError(new Error('Failed to fetch statistics'), error)
      throw error
    }
  }, [])

  return <StatisticsContext.Provider value={{ statistics, fetchStatistics }}>{children}</StatisticsContext.Provider>
}
