import React from 'react'
import PieChart from './PieChart'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

interface Props {
  acceptance: number
}

export const AcceptanceRate = ({ acceptance }: Props) => {
  const { intl } = useTranslation()
  const percentage = Math.round(acceptance * 100)
  const primaryColor =
    percentage > 85 ? 'var(--colors-green)' : percentage > 75 ? 'var(--colors-yellow)' : 'var(--colors-red)'

  return (
    <div className="animate-zoomIn-fast card p-xl w-full">
      <h2 className="text-lg">
        {intl.formatMessage({
          id: 'Acceptance_Rate',
          defaultMessage: 'Acceptance Rate',
          description: ''
        })}
      </h2>

      <PieChart primaryColor={primaryColor} secondaryColor="var(--colors-wind)" percentage={percentage}>
        <h3>{percentage}%</h3>
      </PieChart>
    </div>
  )
}
