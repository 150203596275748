import React, { useContext, useEffect, useState } from 'react'
import { WeekVolume } from './WeekVolume'
import { TodayVolume } from './TodayVolume'
import { Cleaning } from './Cleaning'
import { AcceptanceRate } from './AcceptanceRate'
import { UnlockPremium } from './UnlockPremium'
import { getResourceAccessFromJwt } from '../../lib'
import { StatisticsContext, InstallationsContext, StoreAccessContext } from '../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import usePageBottom from '../../hooks/usePageBottom'
import { ampli } from '../../ampli'

export const StatisticsPage = () => {
  const { intl } = useTranslation()
  const { statistics, fetchStatistics } = useContext(StatisticsContext)
  const { installations } = useContext(InstallationsContext)
  const { userAccessRequests } = useContext(StoreAccessContext)
  const hasNotifyAssistPremium = getResourceAccessFromJwt().product === 'NotifyAssistPremium'
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('loading')
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''
  const reachedBottom = usePageBottom()

  useEffect(() => {
    if (reachedBottom) {
      ampli.pageBottomReached({
        page: 'Statistics'
      })
    }
  }, [reachedBottom])

  useEffect(() => {
    ;(hasNotifyAssistPremium ? fetchStatistics(locationId) : Promise.resolve()).then(
      () => setRequestStatus(''),
      () => setRequestStatus('failed')
    )
  }, [fetchStatistics, hasNotifyAssistPremium])

  if (!hasNotifyAssistPremium) {
    return <UnlockPremium />
  }

  if (requestStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (requestStatus === 'failed') {
    return (
      <div className="centerAbsolute alert alert-danger">
        {intl.formatMessage({
          id: 'Fetch_Statistics_Failed',
          defaultMessage: 'Oh snap! Something went wrong. Please try again later.',
          description: ''
        })}
      </div>
    )
  }

  if (statistics && installations.length > 0) {
    const { acceptance, volume } = statistics
    return (
      <div className="flex flex-col items-start w-full mx-auto max-w-screen-lg space-y-xl p-xl">
        <TodayVolume volume={volume} />
        <WeekVolume volume={volume} />
        <Cleaning installations={installations} />
        <AcceptanceRate acceptance={acceptance} />
      </div>
    )
  } else {
    return (
      <h1 className="centerAbsolute alert alert-warning">
        {intl.formatMessage({
          id: 'No_Statistics',
          defaultMessage: "We've got no data for this store, yet.",
          description: ''
        })}
      </h1>
    )
  }
}
