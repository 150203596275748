import React, { useContext, useState } from 'react'
import { AppMessageContext, InstallationsContext, StoreAccessContext } from '../../contexts'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { ampli } from '../../ampli'

type Props = {
  installation: InstallationType
}

export const RvmName = ({ installation }: Props) => {
  const { name, installationId } = installation
  const { intl } = useTranslation()
  const [newName, setNewName] = useState('')
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('')
  const { updateInstallationName } = useContext(InstallationsContext)
  const { pushMessage } = useContext(AppMessageContext)
  const { userAccessRequests } = useContext(StoreAccessContext)
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''

  const _updateInstallationName = async () => {
    setRequestStatus('loading')

    try {
      await updateInstallationName(locationId, installationId, newName)
      ampli.machineNameChanged()
      setRequestStatus('')
    } catch (error) {
      setRequestStatus('failed')

      pushMessage({
        formattedMessage: (
          <>
            {intl.formatMessage({
              id: 'Update_RVM_Name_Failed',
              defaultMessage: "Couldn't update name at this time. Try again later.",
              description: ''
            })}
          </>
        ),
        type: 'danger',
        duration: 5 * 1000
      })
    }
  }

  const _onBlur = () => {
    if (newName === '') {
      return setNewName(name || '')
    } else if (name !== newName) {
      _updateInstallationName()
    }
  }

  return (
    <div className="my-md flex flex-col items-center">
      <input
        aria-label={`${intl.formatMessage({
          id: 'Rvm_Name',
          defaultMessage: 'RVM name',
          description: ''
        })} ${name}`}
        type="text"
        className="font-bold text-center border-transparent hover:border-grey"
        onChange={evt => setNewName(evt.target.value)}
        value={newName || name}
        onBlur={_onBlur}
        disabled={requestStatus === 'loading'}
      />

      <small>
        {installation.rvmSerialNumber ||
          intl.formatMessage({
            id: 'Unknown_Serial_Number',
            defaultMessage: 'Unknown serial number',
            description: ''
          })}
      </small>
    </div>
  )
}
