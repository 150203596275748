import React, { useCallback, useEffect, useState } from 'react'
import { Dialog } from './common'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

export const AppUpdatedDialog = () => {
  const { intl } = useTranslation()
  const [showUpdateDialog, setShowUpdateDialog] = useState(false)
  const [previousLastModifiedOrEtag, setPreviousLastModifiedOrEtag] = useState<string | null>(null)

  const fetchIndexHtmlLastModifiedOrEtag = useCallback(
    () =>
      fetch('/index.html', { cache: 'no-store' }).then(
        response => response.headers.get('last-modified') || response.headers.get('etag')
      ),
    []
  )

  const requestAndCompareIndexHtml = useCallback(() => {
    fetchIndexHtmlLastModifiedOrEtag()
      .then(lastModifiedOrEtag => {
        if (previousLastModifiedOrEtag === null) {
          setPreviousLastModifiedOrEtag(lastModifiedOrEtag)
        } else if (previousLastModifiedOrEtag !== lastModifiedOrEtag) {
          setShowUpdateDialog(true)
        }
      })
      .catch(() => {
        // errors raised when the end-user couldn't get a hold of the index.html
        // because of connection problems aren't interesting to us
      })
  }, [fetchIndexHtmlLastModifiedOrEtag, previousLastModifiedOrEtag])

  useEffect(() => {
    requestAndCompareIndexHtml()

    const tenMinutes = 1000 * 60 * 10 // 1 second -> 1 minute -> 10 minutes
    const fetchInterval = window.setInterval(requestAndCompareIndexHtml, tenMinutes)

    return () => window.clearInterval(fetchInterval)
  }, [requestAndCompareIndexHtml])

  const _closeDialog = () => {
    setShowUpdateDialog(false)
  }

  const _forceReload = () => {
    window.location.reload()
  }

  return showUpdateDialog ? (
    <Dialog onClose={_closeDialog}>
      <div className="card max-w-25 p-lg space-y-lg">
        <h2 className="text-xl">
          {intl.formatMessage({
            id: 'Download_Updates_Title',
            defaultMessage: 'Download updates',
            description: ''
          })}
        </h2>

        <p>
          {intl.formatMessage({
            id: 'Download_Updates_Description',
            defaultMessage:
              'Notify+Assist has updates ready to be downloaded. Please update as soon as possible by pressing the button below.',
            description: ''
          })}
        </p>

        <button className="btn btn-primary-dark w-full" onClick={_forceReload}>
          {intl.formatMessage({
            id: 'Download_Updates_ButtonText',
            defaultMessage: 'Update now',
            description: ''
          })}
        </button>
      </div>
    </Dialog>
  ) : null
}
