import React from 'react'
import styled from 'styled-components'
import { FormattedRelativeTime } from 'react-intl'
import { BinStatusIndicator } from './BinStatusIndicator'
import { RvmBadge } from './RvmBadge'
import { badgeColorAndIconMap } from './badgeColorAndIconMap'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { RVMFlag, RVMFlagDescription, UIFlag } from '../../lib/rvmFlags'

const Container = styled.div`
  :after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: white;
    left: 50%;
    top: -7px;
    transform: rotate(45deg) translateX(-50%);
  }
`

type Props = {
  installation: InstallationType
}

export const RvmInfo = ({ installation }: Props) => {
  const { intl } = useTranslation()
  const { detail, updatedTime } = installation.currentStatus

  const showStatusInfo =
    detail !== UIFlag.MACHINE_HEALTHY.id &&
    detail !== UIFlag.MACHINE_OFFLINE.id &&
    detail !== RVMFlag.BIN_STATUS_SEMI_FULL.id &&
    detail !== RVMFlag.BIN_STATUS_FULL.id

  const backgroundColor =
    badgeColorAndIconMap[detail]?.colorSetting['normal'].backgroundColor ||
    badgeColorAndIconMap.UNKNOWN.colorSetting['normal'].backgroundColor

  const color =
    badgeColorAndIconMap[detail]?.colorSetting['normal'].iconColor ||
    badgeColorAndIconMap.UNKNOWN.colorSetting['normal'].iconColor

  const secondsSinceUpdatedTime = updatedTime && (Date.parse(updatedTime) - Date.now()) / 1000

  if (detail === UIFlag.MACHINE_OFFLINE.id) {
    return null
  }

  return (
    <Container className="relative w-full">
      {showStatusInfo ? (
        <div className="flex items-center p-md md:rounded-bl-sm md:rounded-br-sm" style={{ color, backgroundColor }}>
          <RvmBadge statusDetail={detail} colorSetting="inverted" small />

          <div className="flex flex-col">
            <b>{intl.formatMessage(RVMFlagDescription[detail] || RVMFlagDescription.UNKNOWN)}</b>
            {secondsSinceUpdatedTime && (
              <span className="text-sm">
                <FormattedRelativeTime value={secondsSinceUpdatedTime} updateIntervalInSeconds={1} numeric="auto" />
              </span>
            )}
          </div>
        </div>
      ) : (
        <BinStatusIndicator binStatus={installation.binStatus} />
      )}
    </Container>
  )
}
