import { defineMessages } from 'react-intl'

export const RVMFlag = defineMessages({
  MACHINE_DOWN: {
    id: 'MACHINE_DOWN',
    defaultMessage: 'Machine is down',
    description: ''
  },
  MACHINE_CLEANING_OVERDUE: {
    id: 'MACHINE_CLEANING_OVERDUE',
    defaultMessage: 'Cleaning overdue',
    description: ''
  },
  BIN_STATUS_FULL: {
    id: 'BIN_STATUS_FULL',
    defaultMessage: 'One or more bins are full',
    description: ''
  },
  BIN_STATUS_SEMI_FULL: {
    id: 'BIN_STATUS_SEMI_FULL',
    defaultMessage: 'One or more bins are almost full',
    description: ''
  },
  TABLE_SEMI_FULL: {
    id: 'TABLE_SEMI_FULL',
    defaultMessage: 'Bottle table is almost full',
    description: ''
  },
  CRATE_CONVEYOR_SEMI_FULL: {
    id: 'CRATE_CONVEYOR_SEMI_FULL',
    defaultMessage: 'Crate conveyor is almost full',
    description: ''
  }
})

export const UIFlag = defineMessages({
  MACHINE_OFFLINE: {
    id: 'MACHINE_OFFLINE',
    defaultMessage: 'Machine is offline',
    description: ''
  },
  MACHINE_HEALTHY: {
    id: 'MACHINE_HEALTHY',
    defaultMessage: 'Machine is healthy',
    description: ''
  },
  CLEANING_OVERDUE: {
    id: 'CLEANING_OVERDUE',
    defaultMessage: 'Cleaning overdue',
    description: ''
  },
  CLEANING_WARNING: {
    id: 'CLEANING_WARNING',
    defaultMessage: 'Needs cleaning',
    description: ''
  },
  CLEANING_TOO_SHORT: {
    id: 'CLEANING_TOO_SHORT',
    defaultMessage: 'Cleaning too short',
    description: ''
  }
})

export const BinStatus = defineMessages({
  TITLE: {
    id: 'BinStatus_Title',
    defaultMessage: 'Bin status',
    description: ''
  },
  FULL: {
    id: 'BinStatus_Full',
    defaultMessage: 'Bin is full',
    description: ''
  },
  SEMI_FULL: {
    id: 'BinStatus_SemiFull',
    defaultMessage: 'Bin is almost full',
    description: ''
  },
  NOT_FULL: {
    id: 'BinStatus_NotFull',
    defaultMessage: 'Bin is not full',
    description: ''
  },
  UNKNOWN: {
    id: 'BinStatus_Unknown',
    defaultMessage: 'Unknown bin status',
    description: ''
  }
})

export const RVMFlagDescription = defineMessages({
  FRONT_DOOR_OPEN: {
    id: 'FRONT_DOOR_OPEN',
    defaultMessage: 'The machine door is open',
    description: ''
  },
  EMERGENCY: {
    id: 'EMERGENCY',
    defaultMessage: 'The emergency stop switch has been activated',
    description: ''
  },
  STANDBY: {
    id: 'STANDBY',
    defaultMessage: 'The machine is in standby',
    description: ''
  },
  EMPTYING_BIN: {
    id: 'EMPTYING_BIN',
    defaultMessage: 'A bin is being emptied',
    description: ''
  },
  FULL_BIN: {
    id: 'FULL_BIN',
    defaultMessage: 'Bin full',
    description: ''
  },
  FULL_TABLE: {
    id: 'FULL_TABLE',
    defaultMessage: 'Full table',
    description: ''
  },
  CLOSED: {
    id: 'CLOSED',
    defaultMessage: 'Closed',
    description: ''
  },
  CHAMBER_BLOCKED: {
    id: 'CHAMBER_BLOCKED',
    defaultMessage: 'Chamber blocked',
    description: ''
  },
  FULL_CONVEYOR: {
    id: 'FULL_CONVEYOR',
    defaultMessage: 'Full conveyor',
    description: ''
  },
  PRINTER_OUT_OF_PAPER: {
    id: 'PRINTER_OUT_OF_PAPER',
    defaultMessage: 'Printer is out of paper',
    description: ''
  },
  PRINTER_STORE_RESOLVABLE: {
    id: 'PRINTER_STORE_RESOLVABLE',
    defaultMessage: 'The printer needs assistance',
    description: ''
  },
  PRINTER_OTHER: {
    id: 'PRINTER_OTHER',
    defaultMessage: 'The printer needs assistance',
    description: ''
  },
  ERROR_BACKROOM: {
    id: 'ERROR_BACKROOM',
    defaultMessage: 'The backroom needs attention',
    description: ''
  },
  ERROR_OTHER: {
    id: 'ERROR_OTHER',
    defaultMessage: 'An error occurred which needs your attention',
    description: ''
  },
  CLOSED_UPDATE: {
    id: 'CLOSED_UPDATE',
    defaultMessage: 'Machine update',
    description: ''
  },
  TEMPORARY_CLOSED: {
    id: 'TEMPORARY_CLOSED',
    defaultMessage: 'Temporary closed',
    description: ''
  },
  TEMPORARY_CLOSED_REAR_DOOR_OPEN: {
    id: 'TEMPORARY_CLOSED_REAR_DOOR_OPEN',
    defaultMessage: 'A rear door is open',
    description: ''
  },
  TEMPORARY_CLOSED_MACHINE_BEING_EMPTIED: {
    id: 'TEMPORARY_CLOSED_MACHINE_BEING_EMPTIED',
    defaultMessage: 'A bin is being emptied',
    description: ''
  },
  TEMPORARY_CLOSED_RESET_BUTTON: {
    id: 'TEMPORARY_CLOSED_RESET_BUTTON',
    defaultMessage: 'Reset button',
    description: ''
  },
  TEMPORARY_CLOSED_NEEDS_EMPTYING: {
    id: 'TEMPORARY_CLOSED_NEEDS_EMPTYING',
    defaultMessage: 'A bin needs to be emptied',
    description: ''
  },
  TEMPORARY_CLOSED_BACKROOM_NOT_READY: {
    id: 'TEMPORARY_CLOSED_BACKROOM_NOT_READY',
    defaultMessage: 'The backroom is not ready',
    description: ''
  },
  UNKNOWN: {
    id: 'UNKNOWN',
    defaultMessage: 'An error occurred which needs your attention',
    description: ''
  }
})
