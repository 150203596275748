export default {
  hr: () => import('./locales/hr.json'),
  da: () => import('./locales/da.json'),
  nl: () => import('./locales/nl.json'),
  en: () => import('./locales/en.json'),
  fi: () => import('./locales/fi.json'),
  fr: () => import('./locales/fr.json'),
  'fr-ca': () => import('./locales/fr-ca.json'),
  de: () => import('./locales/de.json'),
  he: () => import('./locales/he.json'),
  hu: () => import('./locales/hu.json'),
  it: () => import('./locales/it.json'),
  ja: () => import('./locales/ja.json'),
  lv: () => import('./locales/lv.json'),
  no: () => import('./locales/no.json'),
  pl: () => import('./locales/pl.json'),
  pt: () => import('./locales/pt.json'),
  ro: () => import('./locales/ro.json'),
  sk: () => import('./locales/sk.json'),
  es: () => import('./locales/es.json'),
  sv: () => import('./locales/sv.json')
}
