import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Drawer } from '../common'
import { setItemInWebStorage, getItemFromWebStorage } from '../../lib'

export const NotificationsUpdatedDrawer = () => {
  const [visible, setVisible] = useState(false)

  const closeDrawer = () => {
    setItemInWebStorage('UPDATED_NOTIFICATIONS_SHOWN', true)
    setVisible(false)
  }

  useEffect(() => {
    getItemFromWebStorage('UPDATED_NOTIFICATIONS_SHOWN').then(alreadyShown => {
      if (!alreadyShown && !window.Cypress) {
        setVisible(true)
      }
    })
  }, [])

  return (
    <Drawer onClose={closeDrawer} visible={visible}>
      <div className="flex flex-col space-y-lg">
        <h1 className="text-xl">Some news!</h1>

        <p>
          We have done some changes to how push notifications work. You`ll have to enable notifications again if you
          want to continue receiving them.
        </p>

        <Link
          to="settings/notifications"
          className="btn btn-primary-dark w-full"
          onClick={() => {
            closeDrawer()
          }}
        >
          Continue
        </Link>
      </div>
    </Drawer>
  )
}
