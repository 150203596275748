import React from 'react'
import VolumeGraph from './VolumeGraph'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

const oneWeekInMs = 604800000

interface Props {
  volume: VolumeDayType[]
}
function calculateWeekNumber(date) {
  const lastDay = new Date(date)
  lastDay.setUTCDate(lastDay.getUTCDate() + 4 - (lastDay.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(lastDay.getUTCFullYear(), 0, 1))
  return Math.ceil(((lastDay.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
}

export const WeekVolume = ({ volume }: Props) => {
  const { intl } = useTranslation()

  const thisWeekVolume = volume.map(({ date, current }) => ({
    date,
    ...current
  }))

  const lastWeekVolume = volume.map(({ date, oneWeekAgo }) => ({
    date: new Date(new Date(date).getTime() - oneWeekInMs),
    ...oneWeekAgo
  }))

  const weekNumber = calculateWeekNumber(thisWeekVolume[0].date)
  const lastWeekNumber = weekNumber - 1

  return (
    <div className="animate-zoomIn-fast card relative w-full space-y-xl">
      <h2 className="text-lg m-xl">
        {intl.formatMessage(
          {
            id: 'Activity_This_Week',
            defaultMessage: 'Activity this week (Week {weekNumber})',
            description: ''
          },
          { weekNumber }
        )}
      </h2>
      <VolumeGraph volume={thisWeekVolume} />

      <h2 className="text-lg m-xl">
        {intl.formatMessage(
          {
            id: 'Activity_Last_Week',
            defaultMessage: 'Activity last week (Week {lastWeekNumber})',
            description: ''
          },
          { lastWeekNumber }
        )}
      </h2>
      <VolumeGraph volume={lastWeekVolume} />
    </div>
  )
}
