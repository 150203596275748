import React, { useContext } from 'react'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { AlarmBellIcon } from '../../icons'
import { StoreAccessContext } from '../../../contexts'
import { initWebPush, usesiOSNativeWrapper } from '../../../services'
import { NotificationPermissionHelp } from './NotificationPermissionHelp'

export const RootToggle = () => {
  const { intl } = useTranslation()
  const { userAccessRequests } = useContext(StoreAccessContext)
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''

  if (usesiOSNativeWrapper() || Notification?.permission === 'granted') {
    return null
  }

  if (window.Notification?.permission === 'denied') {
    return (
      <div className="alert alert-warning mb-xl">
        <NotificationPermissionHelp />
      </div>
    )
  }

  return (
    <button
      className="btn btn-primary-dark flex items-center justify-between w-full h-3 mb-xl"
      onClick={() => initWebPush(locationId)}
    >
      <div />

      {intl.formatMessage({
        id: 'Enable_Notifications',
        defaultMessage: 'Enable notifications',
        description: ''
      })}

      <AlarmBellIcon width="1.5rem" height="1.5rem" />
    </button>
  )
}
