import { collection, onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { logError } from '@tomra/datadog-browser-logging'
import { FIREBASE } from '../lib'

const getBasePath = (locationId: string) => {
  return `locations/${locationId}/installations`
}

export const subscribeToInstallationsCollection = (
  locationId: string,
  onData: (data: any) => void,
  onMissingOrError?: (error?: any) => void,
  path: string = ''
): Unsubscribe => {
  let unsubscribe: Unsubscribe = () => {}

  try {
    unsubscribe = onSnapshot(
      collection(FIREBASE.db, getBasePath(locationId), path),
      snapshot =>
        snapshot.docs.length > 0
          ? onData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
          : onMissingOrError?.(),
      error => onMissingOrError?.(error)
    )
  } catch (error: any) {
    onMissingOrError?.(error)
    logError(new Error('Failed to subscribe to installations collection from Firestore'), error)
  }

  return unsubscribe
}
