import React from 'react'
import { UserRow } from './UserRow'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

type Props = {
  storeAccessRequests: StoreAccessRequestType[]
}

export const UsersTable = (props: Props) => {
  const { intl } = useTranslation()

  if (props.storeAccessRequests.length === 0) {
    return (
      <div className="centerAbsolute alert alert-warning">
        {intl.formatMessage({
          id: 'No_Store_Access_Requests',
          defaultMessage: 'No such access requests exists for this store at the moment.',
          description: ''
        })}
      </div>
    )
  }

  return (
    <div className="animate-zoomIn-fast card w-full mt-lg">
      <table className="table">
        <thead>
          <tr>
            <th>
              {intl.formatMessage({
                id: 'Name',
                defaultMessage: 'Name',
                description: ''
              })}
            </th>
            <th className="hidden sm:table-cell">
              {intl.formatMessage({
                id: 'Email',
                defaultMessage: 'Email',
                description: ''
              })}
            </th>
            <th className="hidden sm:table-cell">
              {intl.formatMessage({
                id: 'Created_At',
                defaultMessage: 'Created',
                description: ''
              })}
            </th>
            <th>
              {intl.formatMessage({
                id: 'Update_User',
                defaultMessage: 'Update user',
                description: ''
              })}
            </th>
          </tr>
        </thead>

        <tbody>
          {props.storeAccessRequests.map(accessRequest =>
            accessRequest.identity ? (
              <UserRow accessRequest={accessRequest} key={accessRequest.accessRequestId} />
            ) : null
          )}
        </tbody>
      </table>
    </div>
  )
}
