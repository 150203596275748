import React from 'react'
import styled from 'styled-components'
import { logError } from '@tomra/datadog-browser-logging'
import PieChart from './PieChart'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

const InnerContainer = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

interface Props {
  volume: VolumeDayType[]
}

export const TodayVolume = ({ volume }: Props) => {
  const { intl } = useTranslation()
  const today = new Date()
  const todayVolume = volume.find(({ date }) => new Date(date).toDateString() === today.toDateString())

  if (!todayVolume) {
    logError(new Error('Current day is not included in statistics payload'), null, {
      volume,
      todayDate: today.toISOString(),
      utcOffset: today.getTimezoneOffset() * -1
    })

    return (
      <div className="alert alert-danger">
        {intl.formatMessage({
          id: 'Technical_Issues',
          defaultMessage:
            "Whoopsie! Seems we're having some technical issues at the moment. We've been notified and are working on the issue as we speak. Please try again later.",
          description: ''
        })}
      </div>
    )
  }

  const { current, historic } = todayVolume

  const valuePercentage = Math.round((current.value / (historic.value || 1)) * 100)
  const itemCountPercentage = Math.round((current.itemCount / (historic.itemCount || 1)) * 100)
  const sessionCountPercentage = Math.round((current.sessionCount / (historic.sessionCount || 1)) * 100)

  return (
    <div className="animate-zoomIn-fast card p-xl w-full">
      <div className="relative mb-xl">
        <h1 className="text-lg">
          {intl.formatMessage({
            id: 'Activity_Today',
            defaultMessage: 'Activity today',
            description: ''
          })}
        </h1>

        <small>
          {intl.formatMessage({
            id: 'Compared_To_Average',
            defaultMessage: '(Compared to average)',
            description: ''
          })}
        </small>
      </div>

      <InnerContainer className="flex justify-around items-start flex-wrap">
        <div>
          <span>
            {intl.formatMessage({
              id: 'Refund',
              defaultMessage: 'Refund',
              description: ''
            })}
          </span>

          <PieChart
            primaryColor="var(--colors-blue-light)"
            secondaryColor="#D7E8F9"
            percentage={Math.min(100, valuePercentage)}
          >
            <h2>{Math.floor(current.value / 1000)}</h2>
            <span>/{Math.floor(historic.value / 1000)}</span>
          </PieChart>
        </div>

        <div>
          <span>
            {intl.formatMessage({
              id: 'Containers',
              defaultMessage: 'Containers',
              description: ''
            })}
          </span>

          <PieChart
            primaryColor="var(--colors-atlantic)"
            secondaryColor="#C0C0C0"
            percentage={Math.min(100, itemCountPercentage)}
          >
            <h2>{current.itemCount}</h2>
            <span>/{historic.itemCount}</span>
          </PieChart>
        </div>

        <div>
          <span>
            {intl.formatMessage({
              id: 'Customers',
              defaultMessage: 'Customers',
              description: ''
            })}
          </span>

          <PieChart
            primaryColor="var(--colors-green)"
            secondaryColor="#DCFAD8"
            percentage={Math.min(100, sessionCountPercentage)}
          >
            <h2>{current.sessionCount}</h2>
            <span>/{historic.sessionCount}</span>
          </PieChart>
        </div>
      </InnerContainer>
    </div>
  )
}
