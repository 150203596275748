import { AMPLITUDE_CONFIG } from './index'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { ampli } from '../ampli'

//Utility class to hide content for Amplitude session replays
export const AMPLITUDE_HIDE_CLASS_NAME = 'hide-for-amplitude'

export const initAmplitude = () => {
  const disabled = !!window.location.host.match(/(:\d{4})/gi)

  ampli.load({
    disabled,
    client: {
      apiKey: AMPLITUDE_CONFIG.apiKey,
      configuration: {
        autocapture: disabled
          ? false
          : {
              attribution: false,
              pageViews: true,
              sessions: true,
              formInteractions: false,
              fileDownloads: false
            }
      }
    }
  })

  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.01,
    privacyConfig: { blockSelector: `.${AMPLITUDE_HIDE_CLASS_NAME}` }
  })
  ampli.client?.add(sessionReplayTracking)
}

//Temporary functionality to be able to identify users working for Dunnes in Amplitude
const isDunnes = (email: string) => {
  const lowercasedEmail = email.toLowerCase()
  return lowercasedEmail.includes('dunnes-stores.ie') || lowercasedEmail.includes('dunnes.ie')
}

//Temporary functionality to be able to identify users working for Dunnes in Amplitude
export const getAmplitudeChain = (email: string) => (isDunnes(email) ? 'Dunnes Stores IE' : undefined)

export const getAmplitudeEmailType = (email: string) => {
  const emailLowerCase = email.toLowerCase()

  if (emailLowerCase.includes('tomra')) {
    return 'tomra'
  } else if (
    emailLowerCase.includes('gmail') ||
    emailLowerCase.includes('yahoo') ||
    emailLowerCase.includes('outlook') ||
    emailLowerCase.includes('icloud') ||
    emailLowerCase.includes('hotmail')
  ) {
    return 'personal'
  } else {
    return 'other'
  }
}
