import React, { useState } from 'react'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { Drawer } from '../common/Drawer'
import { EnvPickerDrawer } from './EnvPickerDrawer'
import { ENV } from '../../lib'

export const EnvPickerButton = () => {
  const { intl } = useTranslation()
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <>
      <button className="btn bg-white w-full relative" onClick={() => setDrawerOpen(true)}>
        <span className="ml-sm">
          {intl.formatMessage({
            id: 'Select_Region',
            defaultMessage: 'Select region',
            description: ''
          })}
        </span>
        <span className="absolute right-1">({ENV})</span>
      </button>

      <hr />

      <Drawer onClose={() => setDrawerOpen(false)} visible={drawerOpen}>
        <EnvPickerDrawer />
      </Drawer>
    </>
  )
}
