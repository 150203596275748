import React from 'react'
import { getEmailFromJwt, ENV } from '../../lib'
import icebergImage from '../../images/iceberg.png'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

function createUpgradeEmailContent() {
  const subject = 'Upgrade to Notify+Assist Premium'
  const body = `\
  Hi,

  I would like to upgrade to Notify+Assist Premium.
  Here's my info:

  - Account: ${getEmailFromJwt()}
  - Store: ${window.location.href.split('/')[3]}
  - Environment: ${ENV}

  Could you help me?\
  `

  return `subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
}

export const UnlockPremium = () => {
  const { intl } = useTranslation()

  return (
    <div className="flex flex-col justify-center items-center">
      <h1 className="text-xl my-xl">
        {intl.formatMessage({
          id: 'Unlock_Premium_Title',
          defaultMessage: 'Unlock full potential',
          description: ''
        })}
      </h1>

      <img className="w-20" src={icebergImage} alt="Iceberg" />

      <div
        className="bg-white-transparent w-full relative border-t-8 border-blue min-h-screen p-lg"
        style={{ marginTop: '-230px' }}
      >
        <h2 className="text-lg text-center relative mt-16">
          {intl.formatMessage({
            id: 'Unlock_Premium_Description',
            defaultMessage: 'Upgrade to premium to be able to get a better look at your customers behaviours.',
            description: ''
          })}
        </h2>

        <a href={`mailto:marketsupport.tomraproducts@tomra.com?${createUpgradeEmailContent()}`}>
          <button className="btn btn-primary-dark relative mx-auto mt-xl">
            {intl.formatMessage({
              id: 'Unlock_Premium_UpgradeButton',
              defaultMessage: 'Contact us to upgrade',
              description: ''
            })}
          </button>
        </a>
      </div>
    </div>
  )
}
