/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser'

export type Environment = 'notifyassistprod'

export const ApiKey: Record<Environment, string> = {
  notifyassistprod: '8e7bc5cdb30ac9206636a404bd1e274e'
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'web',
    versionId: '0d1cc32a-ae14-4048-8b34-322fc00eed7c'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
}

export interface LoadOptionsBase {
  disabled?: boolean
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment
  client?: { configuration?: BrowserOptions }
}
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } }
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } }

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  active_notifications?: string[]
  chain?: string
  email_type?: string
  user_role?: string
}

export interface AccountDeactivatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  days_since_created?: number
}

export interface LanguageSelectedProperties {
  language_code: string
}

export interface NotificationSwitchedProperties {
  enabled: boolean
  notification_type: string
}

export interface PageBottomReachedProperties {
  page: string
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties
  }
}

export class AccessRequested implements BaseEvent {
  event_type = 'Access Requested'
}

export class AccountDeactivated implements BaseEvent {
  event_type = 'Account Deactivated'

  constructor(public event_properties?: AccountDeactivatedProperties) {
    this.event_properties = event_properties
  }
}

export class AccountDeleted implements BaseEvent {
  event_type = 'Account Deleted'
}

export class LanguageSelected implements BaseEvent {
  event_type = 'Language Selected'

  constructor(public event_properties: LanguageSelectedProperties) {
    this.event_properties = event_properties
  }
}

export class LoginInitiated implements BaseEvent {
  event_type = 'Login Initiated'
}

export class MachineNameChanged implements BaseEvent {
  event_type = 'Machine Name Changed'
}

export class NotificationSwitched implements BaseEvent {
  event_type = 'Notification Switched'

  constructor(public event_properties: NotificationSwitchedProperties) {
    this.event_properties = event_properties
  }
}

export class PageBottomReached implements BaseEvent {
  event_type = 'Page Bottom Reached'

  constructor(public event_properties: PageBottomReachedProperties) {
    this.event_properties = event_properties
  }
}

export class RequestAccessViewed implements BaseEvent {
  event_type = 'Request Access Viewed'
}

export class UserAuthenticated implements BaseEvent {
  event_type = 'User Authenticated'
}

export type PromiseResult<T> = { promise: Promise<T | void> }

const getVoidPromiseResult = () => ({ promise: Promise.resolve() })

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Access Requested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Access%20Requested)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  accessRequested(
    options?: EventOptions,
  ) {
    return this.track(new AccessRequested(), options);
  }

  /**
   * Account Deactivated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Account%20Deactivated)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. days_since_created)
   * @param options Amplitude event options.
   */
  accountDeactivated(
    properties?: AccountDeactivatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountDeactivated(properties), options);
  }

  /**
   * Account Deleted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Account%20Deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  accountDeleted(
    options?: EventOptions,
  ) {
    return this.track(new AccountDeleted(), options);
  }

  /**
   * Language Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Language%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. language_code)
   * @param options Amplitude event options.
   */
  languageSelected(
    properties: LanguageSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LanguageSelected(properties), options);
  }

  /**
   * Login Initiated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Login%20Initiated)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  loginInitiated(
    options?: EventOptions,
  ) {
    return this.track(new LoginInitiated(), options);
  }

  /**
   * Machine Name Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Machine%20Name%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  machineNameChanged(
    options?: EventOptions,
  ) {
    return this.track(new MachineNameChanged(), options);
  }

  /**
   * Notification Switched
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Notification%20Switched)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. enabled)
   * @param options Amplitude event options.
   */
  notificationSwitched(
    properties: NotificationSwitchedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NotificationSwitched(properties), options);
  }

  /**
   * Page Bottom Reached
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Page%20Bottom%20Reached)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. page)
   * @param options Amplitude event options.
   */
  pageBottomReached(
    properties: PageBottomReachedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageBottomReached(properties), options);
  }

  /**
   * Request Access Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/Request%20Access%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  requestAccessViewed(
    options?: EventOptions,
  ) {
    return this.track(new RequestAccessViewed(), options);
  }

  /**
   * User Authenticated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Notify%20%26%20Assist%20Prod/events/main/latest/User%20Authenticated)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  userAuthenticated(
    options?: EventOptions,
  ) {
    return this.track(new UserAuthenticated(), options);
  }
}

export const ampli = new Ampli()

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions

export type BrowserClient = amplitude.Types.BrowserClient
export type BaseEvent = amplitude.Types.BaseEvent
export type IdentifyEvent = amplitude.Types.IdentifyEvent
export type GroupEvent = amplitude.Types.GroupIdentifyEvent
export type Event = amplitude.Types.Event
export type EventOptions = amplitude.Types.EventOptions
export type Result = amplitude.Types.Result
