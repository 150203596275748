import React, { useState } from 'react'
import { RvmBadge } from '../status/RvmBadge'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { UIFlag } from '../../lib/rvmFlags'

type CleaningStatusMapType = {
  [key: string]: {
    message: { id: string; defaultMessage: string; description?: string }
    backgroundColor: string
    color: string
  }
}

const cleaningStatusMessageMap: CleaningStatusMapType = {
  CLEANING_OVERDUE: {
    message: UIFlag.CLEANING_OVERDUE,
    backgroundColor: 'bg-red',
    color: 'text-white'
  },
  CLEANING_WARNING: {
    message: UIFlag.CLEANING_WARNING,
    backgroundColor: 'bg-yellow',
    color: 'text-black'
  },
  CLEANING_TOO_SHORT: {
    message: UIFlag.CLEANING_TOO_SHORT,
    backgroundColor: 'bg-yellow',
    color: 'text-black'
  }
}

function determineCleaningStatus(daysLeftUntilExtensiveClean: number, daysLeftUntilShortClean?: number) {
  if (daysLeftUntilExtensiveClean <= 0) {
    return UIFlag.CLEANING_OVERDUE
  } else if (daysLeftUntilExtensiveClean <= 2) {
    return UIFlag.CLEANING_WARNING
  } else if (daysLeftUntilShortClean !== undefined && daysLeftUntilShortClean <= 0) {
    return UIFlag.CLEANING_TOO_SHORT
  } else {
    return UIFlag.MACHINE_HEALTHY
  }
}

interface Props {
  installation: InstallationType
  daysLeftUntilExtensiveClean: number
  daysLeftUntilShortClean?: number
}

export const CleaningRvm = ({ installation, daysLeftUntilExtensiveClean, daysLeftUntilShortClean }: Props) => {
  const { intl } = useTranslation()
  const [machineType, setMachineType] = useState(installation.machineType || 'UNKNOWN')
  const cleaningStatus = determineCleaningStatus(daysLeftUntilExtensiveClean, daysLeftUntilShortClean)
  const color = cleaningStatusMessageMap[cleaningStatus.id]?.color || ''
  const backgroundColor = cleaningStatusMessageMap[cleaningStatus.id]?.backgroundColor || ''

  const _onImageLoadError = () => {
    setMachineType('UNKNOWN')
  }

  return (
    <div className="m-lg inline-flex flex-col items-center">
      <div className="relative">
        <img className="h-7" alt="Rvm model" src={`/images/rvms/${machineType}.svg`} onError={_onImageLoadError} />

        <RvmBadge className="absolute top-1/2 -left-lg" statusDetail={cleaningStatus.id} small />
      </div>

      <small className="w-6 text-center truncateText">{installation.name}</small>

      {cleaningStatus !== UIFlag.MACHINE_HEALTHY && (
        <div className={`p-sm rounded-half mt-sm ${color} ${backgroundColor}`}>
          {intl.formatMessage(cleaningStatusMessageMap[cleaningStatus.id].message)}
        </div>
      )}
    </div>
  )
}

export default CleaningRvm
