import { authenticatedFetchData, resolveMondayDate, resolveNextMondayDate } from '../lib'

export async function fetchStatistics(storeId: string) {
  const now = new Date()
  const normalizedUtcOffsetInMinutes = now.getTimezoneOffset() * -1

  const { acceptance, volume } = await authenticatedFetchData(
    `/store-access/v1.0/statistics/store/${storeId}/${resolveMondayDate(now, normalizedUtcOffsetInMinutes)}/${resolveNextMondayDate(
      now,
      normalizedUtcOffsetInMinutes
    )}?utcOffset=${normalizedUtcOffsetInMinutes}`
  ).run()

  return {
    acceptance: acceptance?.current?.rate,
    volume
  }
}
