import React, { FunctionComponent } from 'react'
import CleaningRvm from './CleaningRvm'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

type Props = {
  installations: InstallationType[]
}

export const Cleaning: FunctionComponent<Props> = ({ installations }) => {
  const { intl } = useTranslation()

  const millisToDays = dateInMillis => {
    const seconds = dateInMillis / 1000
    const minutes = seconds / 60
    return minutes / (60 * 24)
  }

  const calculateDaysBetween = (fromDate, toDate) => {
    const fromDays = Math.floor(millisToDays(new Date(fromDate).getTime()))
    const toDays = Math.floor(millisToDays(new Date(toDate).getTime()))
    return toDays - fromDays
  }

  const sortByCleanDates = (installation1: InstallationType, installation2: InstallationType) => {
    const cleanDate1 = new Date(installation1.recommendedCleanDate || 0)
    const cleanDate2 = new Date(installation2.recommendedCleanDate || 0)
    return cleanDate1.getTime() - cleanDate2.getTime()
  }

  const sortedInstallations = installations.sort(sortByCleanDates)
  const nowDate = new Date().toISOString()

  return (
    <div className="animate-zoomIn-fast card p-xl w-full">
      <h2 className="text-lg">
        {intl.formatMessage({
          id: 'Cleaning',
          defaultMessage: 'Cleaning',
          description: ''
        })}
      </h2>

      <div className="flex flex-wrap justify-center">
        {sortedInstallations.map(installation => {
          // Fallback to 0 days left if the machine doesn't have
          // a recommended clean date (i.e it hasnt been cleaned yet)
          const daysLeftUntilExtensiveClean = installation.recommendedCleanDate
            ? calculateDaysBetween(nowDate, installation.recommendedCleanDate)
            : 0

          // Fallback to null for machines that doesn't have a short clean program
          const daysLeftUntilShortClean = installation.recommendedShortProgramCleanDate
            ? calculateDaysBetween(nowDate, installation.recommendedShortProgramCleanDate)
            : undefined

          return (
            <CleaningRvm
              installation={installation}
              daysLeftUntilExtensiveClean={daysLeftUntilExtensiveClean}
              daysLeftUntilShortClean={daysLeftUntilShortClean}
              key={installation.installationId}
            />
          )
        })}
      </div>
    </div>
  )
}
