import React from 'react'
import { toggleTranslations } from './notificationTypeMessageMap'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'

interface Props {
  toggle: NotificationToggleType
  onToggle: (id: string, enabled: boolean) => void
}

export const Toggle = ({ toggle, onToggle }: Props) => {
  const { intl } = useTranslation()

  return (
    <div className="flex items-center justify-between my-lg pb-md border-b-2 border-grey-light">
      <label htmlFor={`${toggle.id}-switch`} className="mr-md">
        {intl.formatMessage(toggleTranslations[toggle.id])}
      </label>

      <input
        id={`${toggle.id}-switch`}
        type="checkbox"
        className="switch"
        checked={toggle.enabled}
        onChange={() => onToggle(toggle.id, !toggle.enabled)}
      />
    </div>
  )
}
