import React, { useEffect, useState, useContext } from 'react'
import { NotificationsContext, StoreAccessContext } from '../../../contexts'
import { Toggle } from './Toggle'
import { UnlockPremium } from './UnlockPremium'
import { RootToggle } from './RootToggle'
import { getResourceAccessFromJwt } from '../../../lib'
import { useTranslation } from '@tomra/react-i18n-scripts/dist/lib/ReactI18nProvider'
import { initAPNsPush, initWebPush, usesiOSNativeWrapper } from '../../../services'
import { ampli } from '../../../ampli'

export const NotificationsInfo = () => {
  const { intl } = useTranslation()
  const { userAccessRequests } = useContext(StoreAccessContext)
  const hasNotifyAssistPremium = getResourceAccessFromJwt().product === 'NotifyAssistPremium'
  const { toggles, updateToggles } = useContext(NotificationsContext)
  const premiumToggles = toggles.filter(toggle => toggle.premium)
  const nonPremiumToggles = toggles.filter(toggle => !toggle.premium)
  const [hasAcceptedNotifications, setHasAcceptedNotifications] = useState(false)
  const locationId = userAccessRequests.find(request => request.status === 'APPROVED')?.locationId || ''

  useEffect(() => {
    if (usesiOSNativeWrapper()) {
      initAPNsPush(locationId).then(() => setHasAcceptedNotifications(true))
    } else {
      initWebPush(locationId).then(() => setHasAcceptedNotifications(true))
    }
  }, [])

  const _toggleNotificationType = (id: string, enabled: boolean) => {
    const updatedToggles = toggles.map(toggle => (toggle.id === id ? { ...toggle, enabled } : toggle))
    updateToggles(updatedToggles, locationId)
    ampli.notificationSwitched({ notification_type: id, enabled })
  }

  if (toggles.length === 0) {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  return (
    <div className="px-md">
      <RootToggle />

      {hasAcceptedNotifications && (
        <>
          <p className="mb-3 text-md font-bold">
            {intl.formatMessage({
              id: 'Recieve_Notifications_For',
              defaultMessage: 'Receive notifications for:',
              description: ''
            })}
          </p>

          {nonPremiumToggles.map(toggle => (
            <Toggle key={toggle.id} toggle={toggle} onToggle={_toggleNotificationType} />
          ))}

          <div className="relative mt-3">
            <h2 className="text-lg font-bold">
              {intl.formatMessage({ id: 'Pre_Warning_Title', defaultMessage: 'Pre-warnings', description: '' })}
            </h2>

            {!hasNotifyAssistPremium && <UnlockPremium />}

            {premiumToggles.map(toggle => (
              <Toggle key={toggle.id} toggle={toggle} onToggle={_toggleNotificationType} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
