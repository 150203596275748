import { defineMessages } from 'react-intl'

export const toggleTranslations = defineMessages({
  FRONT_DOOR_OPEN: {
    id: 'Door_Open_Description',
    defaultMessage: 'Front or back door open on the machine',
    description: ''
  },
  EMERGENCY: {
    id: 'Emergency_Switch_Title',
    defaultMessage: 'Emergency switch',
    description: ''
  },
  STANDBY: {
    id: 'Standby_Title',
    defaultMessage: 'Standby',
    description: ''
  },
  FULL_TABLE: {
    id: 'Full_Table_Description',
    defaultMessage: 'The bottle table in the backroom is full',
    description: ''
  },
  CLOSED: {
    id: 'Closed_Description',
    defaultMessage: 'Machine closed',
    description: ''
  },
  CHAMBER_BLOCKED: {
    id: 'Chamber_Blocked_Description',
    defaultMessage: 'A discarded item is left in the machine.',
    description: ''
  },
  FULL_CONVEYOR: {
    id: 'Full_Conveyor_Description',
    defaultMessage: 'The crate conveyor is full or blocked',
    description: ''
  },
  PRINTER_OUT_OF_PAPER: {
    id: 'PrinterOutOfPaper_Title',
    defaultMessage: 'Printer out of paper',
    description: ''
  },
  PRINTER_STORE_RESOLVABLE: {
    id: 'PrinterStoreResolvable_Title',
    defaultMessage: 'Printer needs attention',
    description: ''
  },
  PRINTER_OTHER: {
    id: 'PrinterOther_Title',
    defaultMessage: 'General printer error',
    description: ''
  },
  ERROR_BACKROOM: {
    id: 'Backroom_Error_Title',
    defaultMessage: 'Backroom errors',
    description: ''
  },
  ERROR_OTHER: {
    id: 'General_Alarms_Title',
    defaultMessage: 'General alarms',
    description: ''
  },
  CLOSED_UPDATE: {
    id: 'CLOSED_UPDATE',
    defaultMessage: 'Machine update',
    description: ''
  },
  TEMPORARY_CLOSED: {
    id: 'TEMPORARY_CLOSED',
    defaultMessage: 'Temporary closed',
    description: ''
  },
  TEMPORARY_CLOSED_RESET_BUTTON: {
    id: 'TEMPORARY_CLOSED_RESET_BUTTON',
    defaultMessage: 'Reset button has been pressed',
    description: ''
  },
  FULL_BIN: {
    id: 'Bin_Full_Title',
    defaultMessage: 'A bin is full. The machine has stopped',
    description: ''
  },
  SINGLE_BIN_FULL: {
    id: 'Single_Bin_Full_Title',
    defaultMessage: 'A bin is full. Machine is still operational',
    description: ''
  },
  ASSISTANCE_NEEDED: {
    id: 'Assistance_Needed_Title',
    defaultMessage: 'Assistance requested',
    description: ''
  },
  BIN_STATUS_SEMI_FULL: {
    id: 'BIN_STATUS_SEMI_FULL',
    defaultMessage: 'Semi full bin',
    description: ''
  },
  TABLE_SEMI_FULL: {
    id: 'Table_Semi_Full_Title',
    defaultMessage: 'Semi full table',
    description: ''
  },
  CRATE_CONVEYOR_SEMI_FULL: {
    id: 'Crate_Conveyor_Semi_Full_Title',
    defaultMessage: 'Semi full crate conveyor',
    description: ''
  }
})
